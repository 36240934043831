export function findProvinceFromAddress(addressPlace: any) {
    const addressComponents = addressPlace.address_components;
    let province: string = '';
    if (addressComponents) {
      const provinceComponent = addressComponents.find(component =>
        component.types.includes('administrative_area_level_2')
      );
      province = provinceComponent ? provinceComponent.short_name : '';
    }
    return province;
}