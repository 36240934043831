import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerFormComponent } from '../customer-form/customer-form.component';

@Component({
  selector: 'app-customer-form-decorator',
  templateUrl: './customer-form-decorator.component.html',
  styleUrls: ['./customer-form-decorator.component.scss']
})
export class CustomerFormDecoratorComponent implements OnInit {

  @ViewChild(CustomerFormComponent, { static: false }) form: CustomerFormComponent;
  constructor() { }

  ngOnInit(): void {
  }

}
