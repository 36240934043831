import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Delivery, DeliveryProduct, DeliveryStatus, DeliveryStatusAux, TimePeriods } from 'src/app/models/delivery.schema';
import { Expert, ExpertServices } from 'src/app/models/expert.schema';
import { Partner } from 'src/app/models/partner.schema';
import { Product } from 'src/app/models/product.schema';
import { ServiceModel } from 'src/app/models/service.schems';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ExpertService } from 'src/app/services/expert.service';
import { PartnerService } from 'src/app/services/partner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from 'src/app/services/product.service';
import { Service } from 'src/app/services/service.service';
import { ProvincesService } from 'src/app/services/provinces.service';
import { PartnerPriorityListService } from 'src/app/services/partner-priority-list.service';
import { FormArray, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { CommanService } from 'src/app/services/comman.service';
import { findProvinceFromAddress } from 'src/app/helpers/find-province-from-address.helper';

@Component({
  selector: 'app-business-sale-form-dialog',
  templateUrl: './business-sale-form-dialog.component.html',
  styleUrls: ['./business-sale-form-dialog.component.scss']
})
export class BusinessSaleFormDialogComponent implements OnInit {

  public timePeriods = TimePeriods;
  public showTime: boolean = true;
  public groups: any;
  public partners: any[];
  public experts: Expert[];
  public deliveryStatus = DeliveryStatus;
  public deliveryStatusAux = DeliveryStatusAux;
  public businessSaleForm: any;
  public saleData: any;
  public products: any;
  public productss: Product[];
  public readonly formValidationMsgs;
  public tmpBtnDisabled: boolean;
  public pickUpTime: string;
  public from: any;
  public to: any;
  public productData: any[];
  public selectedPartner: Partner;
  public selectedPartnerServices: any[] = [];
  public selectedService: any;
  public services: ServiceModel[] = [];
  public previousValues: { startTime: string, endTime: string } = { startTime: '07:00', endTime: '08:00' };
  public selectedProducts: any;
  public TotalSelectedProPrice: any;
  showStartEndTime: boolean = false;
  showPickUpAddress: boolean = false;
  showProductValue: boolean = false;
  showDistanceInput: boolean = false;
  disableAdditionalPriceInput: boolean = false;
  disableValetAdditionalPriceInput: boolean = false;
  pickUpAddressLat: number;
  pickUpAddressLng: number;
  pickUpFTime: any;
  pickUpTTime: any;
  selectedExpert: Expert;
  selectedExpertSalary: any;
  totalDeliveryTime: any = 1;
  selectedExpertServices: any[] = [];
  selectedExpertService: ExpertServices;
  selectedValetAddress: string = '';
  valetAddressLat: number;
  valetAddressLng: number;
  previousValetTimeValues: { valetStartTime: string, valetEndTime: string } = { valetStartTime: '07:00', valetEndTime: '08:00' };
  totalValetTimeDifference: any = 1;
  showValetStartAndEndTime: boolean = false;
  showProductImage: boolean[] = [];
  productImageUrls: string[] = [];
  allProvinces: any[] = [];
  salePartner: any;
  partnerPriorityLists: any[] = [];
  uniqueProductSale: boolean = false;
  availablePartnersListNonUniquePro: any[] = [];
  showPartnersListNonUniquePro: boolean = false;
  proposalBtn: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { saleOrder: any },
    private authStatusService: AuthStatusService,
    private partnerService: PartnerService,
    private expertService: ExpertService,
    private deliveryService: DeliveryService,
    private dialogRef: MatDialogRef<BusinessSaleFormDialogComponent>,
    private _snackBar: MatSnackBar,
    private productService: ProductService,
    private service: Service,
    private provincesService: ProvincesService,
    private partnerPriorityListService:PartnerPriorityListService,
    private commanService:CommanService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.formValidationMsgs = Delivery.validationMessages;
  }

  async ngOnInit() {
    let data = [];
    this.saleData = this.data.saleOrder;
    this.products = JSON.parse(this.data.saleOrder['lineItems']);
    let shippingAddress = JSON.parse(this.data.saleOrder['shippingAddress']);
    let oldProduct: any;
    for(let product of this.products) {
      if(product.sku != null && product.sku != ''){
        oldProduct = await this.productService.findProduct(product.sku);
        if(oldProduct){
          data.push(oldProduct);
        } else {
          let skuProvince = product?.sku + shippingAddress?.province_code;
          oldProduct = await this.productService.findProduct(skuProvince);
          if(!oldProduct){
            // let newProduct = await this.createNewProduct(product, shippingAddress);
            let newProduct = await this.createNewProductWithShopifyProduct(product, shippingAddress);
            data.push(newProduct);
          } else {
            data.push(oldProduct);
          }
        }
      } else{
        // let newProduct = await this.createNewProduct(product, shippingAddress);
        let newProduct = await this.createNewProductWithShopifyProduct(product, shippingAddress);
        data.push(newProduct);
      }
    }
    this.productData = data;
    await this.initialize();
  }

  async createNewProduct(product: Product, shippingAddress: any){
    let newProduct: Product = new Object();
    newProduct.name = product.name;
    newProduct.sku = `${product?.sku ? product?.sku : ''}${shippingAddress?.province_code}`;
    newProduct.category = [];
    newProduct.line = [];
    newProduct.stock = [];
    newProduct.price = product.price;
    return this.productService.create(newProduct);
  }

  async createNewProductWithShopifyProduct(productInfo: any, shippingAddress: any){
    let obj = {
      "info": {
        "productInfo": productInfo,
        "shippingAddress": shippingAddress,
        "platform": "business"
      }
    }

    let stringifyData = JSON.stringify(obj);
    return await this.commanService.createProductWithShopifyProduct(obj);
  }

  async initialize() {
    this.tmpBtnDisabled = false;
    if (this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader')) {
      try {
        this.productss = (await this.productService.getAllActiveProducts(undefined, undefined, { 'Product.name': 'ASC' })).products;
        this.partners = (await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
        this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
        this.services = await this.service.getAllServices();
        this.allProvinces = await this.provincesService.getAllProvinces();
        this.partnerPriorityLists = await this.partnerPriorityListService.getAllPartnerPriorityLists();
      }catch{}
    }
    this.businessSaleForm = new FormGroup(Delivery.validation());
    this.patch();
  }

  patch(){
    if(this.saleData.deliveryDate){
      this.businessSaleForm.controls['deliveryDate'].setValue(new DatePipe('it-IT').transform(this.saleData.deliveryDate, 'yyyy-MM-dd'));
    }
    let beforeFromTime: any;
    if(this.saleData.deliveryTime){
      let [from, to] = this.saleData.deliveryTime.split("-");
      let fromTime = `${from}:00`;
      let toTime = `${parseInt(from)+1}:00`;
      this.from = `${from}:00:00`;
      this.to = `${parseInt(from)+1}:00:00`;
      this.businessSaleForm.controls['fromTime'].setValue(fromTime);
      this.businessSaleForm.controls['toTime'].setValue(toTime);
      /* For Pickuptime 1 hour before delivery time */
      let fromHour = parseInt(from);
      let beforeFromHour = (fromHour === 0 ? 23 : fromHour - 1);
      let beforeToHour = fromHour;
      beforeFromTime = `${beforeFromHour.toString().padStart(2, '0')}:00-${beforeToHour.toString().padStart(2, '0')}:00`;
      /* For Pickuptime 1 hour before delivery time */
    } else {
      this.from = `07:00:00`;
      this.to = `08:00:00`;
      this.businessSaleForm.controls['fromTime'].setValue('07:00');
      this.businessSaleForm.controls['toTime'].setValue('08:00');
      /* For Pickuptime 1 hour before delivery time */
      beforeFromTime = `06:00:00-07:00:00`;
      /* For Pickuptime 1 hour before delivery time */
    }
    this.businessSaleForm.controls['pickUpTime'].setValue(beforeFromTime);
    this.businessSaleForm.controls['surname'].setValue(this.saleData.lastName);
    this.businessSaleForm.controls['name'].setValue(this.saleData.firstName);
    this.businessSaleForm.controls['receiverPhone'].setValue(this.saleData.phone);
    this.businessSaleForm.controls['senderName'].setValue(this.saleData.senderName);
    this.businessSaleForm.controls['senderSurname'].setValue(this.saleData.senderSurname);
    this.businessSaleForm.controls['senderPhone'].setValue(this.saleData.senderPhone);
    this.businessSaleForm.controls['address'].setValue(this.saleData.address);
    this.businessSaleForm.controls['notes'].setValue(this.saleData.notes);
    this.businessSaleForm.controls['intercom'].setValue(this.saleData.intercom);
    // this.businessSaleForm.controls['ddtNumber'].setValue(this.saleData.orderId);
    this.businessSaleForm.controls['ddtNumber'].setValue(this.saleData.actualOrderId);
    /* Set products */
    const control = <FormArray>this.businessSaleForm.get('deliveryProducts');
    control.clear();
    this.productData.forEach((product, key) => {
      control.push(this.patchValues(product, key));
    });
    for(let p of this.partners){
      if(p.businessName.toLowerCase() == this.saleData.vendor.toLowerCase()){
        this.businessSaleForm.controls.partner.controls.id.setValue(p.id);
        this.selectedPartner = p;
        this.selectedPartnerServices = this.selectedPartner?.partnerServices;
        let service = this.selectedPartnerServices.find((services) => {
          return services?.service?.pricingModel?.toLowerCase() === 'sales'
        });
        this.businessSaleForm.controls.service.setValue(service?.service?.id);
        this.handleServiceChange(service?.service?.id);
        break;
      }
    }
    // /* Set products */
    // const control = <FormArray>this.businessSaleForm.get('deliveryProducts');
    // control.clear();
    // this.productData.forEach((product, key) => {
    //   control.push(this.patchValues(product, key));
    // });

  }

  patchValues(deliveryProduct: DeliveryProduct, key){
    let productForm = Delivery.productFormGroup();
    if (deliveryProduct['id']){
      productForm.controls.product.setValue({ id: deliveryProduct['id'], quantity: this.products[key].quantity });
    }
    return productForm;
  }

  handleServiceChange(event: any){

    this.selectedService = this.selectedPartnerServices.find((item) => {
      return item?.service?.id == event;
    });

    this.businessSaleForm.controls.serviceType.setValue(this.selectedService?.service?.pricingModel);
    this.businessSaleForm.controls.serviceName.setValue(this.selectedService?.service?.serviceName);

    this.businessSaleForm.controls.startTime.setValue(this.previousValues.startTime);
    this.businessSaleForm.controls.endTime.setValue(this.previousValues.endTime);
    let pickUpAddress: string = (this.selectedPartner ? this.selectedPartner?.address : '');


    if(this.selectedService?.service?.pricingModel === 'fixedprice'){
      this.businessSaleForm.controls.pickUpAddress.setValue(pickUpAddress);
      this.businessSaleForm.controls.price.setValue(this.selectedService?.price);
      this.businessSaleForm.controls.startTime.setValue(null);
      this.businessSaleForm.controls.endTime.setValue(null);
      this.businessSaleForm.controls.productValue.setValue(null);
      this.businessSaleForm.controls.distance.setValue(null);
      this.businessSaleForm.controls.hours.setValue(0);
      this.businessSaleForm.controls.valetStartTime.setValue(null);
      this.businessSaleForm.controls.valetEndTime.setValue(null);
    }

    if(this.selectedService?.service?.pricingModel === 'hourlyrate'){
      this.businessSaleForm.controls.distance.setValue(null);
      this.businessSaleForm.controls.startTime.setValue(this.previousValues.startTime);
      this.businessSaleForm.controls.endTime.setValue(this.previousValues.endTime);
      this.businessSaleForm.controls.fromTime.setValue(this.previousValues.startTime);
      this.businessSaleForm.controls.toTime.setValue(this.previousValues.endTime);
      this.businessSaleForm.controls.price.setValue(this.selectedService?.price);
      this.businessSaleForm.controls.productValue.setValue(null);
      this.businessSaleForm.controls.pickUpAddress.setValue(pickUpAddress);
      this.totalValetTimeDifference = 1;
      this.businessSaleForm.controls.valetStartTime.setValue(this.previousValues.startTime);
      this.businessSaleForm.controls.valetEndTime.setValue(this.previousValues.endTime);
      this.previousValetTimeValues.valetStartTime = this.businessSaleForm.value.startTime;
      this.previousValetTimeValues.valetEndTime = this.businessSaleForm.value.endTime;
    }

    if(this.selectedService?.service?.pricingModel === 'sales'){
      this.businessSaleForm.controls.pickUpAddress.setValue(pickUpAddress);
      this.businessSaleForm.controls.startTime.setValue(null);
      this.businessSaleForm.controls.distance.setValue(null);
      this.businessSaleForm.controls.endTime.setValue(null);
      this.businessSaleForm.controls.hours.setValue(0);
      this.businessSaleForm.controls.valetStartTime.setValue(null);
      this.businessSaleForm.controls.valetEndTime.setValue(null);
      this.setProductValue();
    }
    let flexbleDelivery: boolean = this.selectedService?.service?.pricingModel === 'fixedprice';
    let deluxyDelivery: boolean = this.selectedService?.service?.pricingModel === 'sales';
    this.businessSaleForm.controls.requestExpert.setValue(flexbleDelivery);
    this.businessSaleForm.controls.deluxyDelivery.setValue(deluxyDelivery);
    this.showStartEndTime = this.selectedService?.service?.pricingModel === 'hourlyrate';
    this.showProductValue = this.selectedService?.service?.pricingModel === 'sales';
    this.showPickUpAddress = this.selectedService?.service?.pricingModel === 'fixedprice';
    this.showDistanceInput = this.selectedService?.service?.pricingModel === 'fixedprice';
    this.expertServiceAndSalary();
  }

  setProductValue(){
    if(this.selectedService?.service?.pricingModel === 'sales'){
      this.selectedProducts = this.businessSaleForm.controls.deliveryProducts?.value;
      this.TotalSelectedProPrice = this.selectedProducts?.map(item => {
        const product = this.productss.find(p => p.id == item.product.id);
        const quantity = Number(item?.product?.quantity);
        const price = Number(product?.price);
        if (!isNaN(price) && !isNaN(quantity)) {
            return price * quantity;
        } else {
            this.successMessage('Can not calculate price and product value for product');
            return false;
            return 0;
          }
      }).reduce((acc, curr) => acc + curr, 0);

      this.businessSaleForm.controls.price?.setValue(this.TotalSelectedProPrice ? (this.selectedService?.price * this.TotalSelectedProPrice)/100 : 0);
      this.businessSaleForm.controls.productValue?.setValue(this.TotalSelectedProPrice ? this.TotalSelectedProPrice : 0);
    }
  }

  expertServiceAndSalary(){
    if(this.selectedExpert){
      if(["fixedprice", "sales"].includes(this.selectedService?.service?.pricingModel?.toLowerCase())){
        this.selectedExpertService = this.selectedExpert?.expertServices.find((expertService: any) => {
          let data = expertService?.service?.serviceType?.toLowerCase() === "fixedpricesalary";
          return data;
        })
        this.calculateFixedPriceValetSalary();
      } else if(this.selectedService?.service?.pricingModel?.toLowerCase() === "hourlyrate") {
        this.selectedExpertService = this.selectedExpert?.expertServices.find((expertService: any) => {
          let data = expertService?.service?.serviceType?.toLowerCase() === "hourlyratesalary";
          return data;
        });
        this.calculateHourlyRateValetSalary();
      }
      this.businessSaleForm.controls.expertServiceId.setValue(this.selectedExpertService?.id);
    }
  }

  /*
  async calculateFixedPriceValetSalary(){
    if(this.selectedExpert){
      this.businessSaleForm.controls.valetStartTime.setValue(null);
      this.businessSaleForm.controls.valetEndTime.setValue(null);
      this.showValetStartAndEndTime = false;
      let pickUpAddressResult: any[];
      if(this.businessSaleForm.controls.pickUpAddress.value){
        pickUpAddressResult = await this.getLatLngByAddress(this.businessSaleForm.controls.pickUpAddress.value ? this.businessSaleForm.controls.pickUpAddress.value : '');
      }

      if(pickUpAddressResult && pickUpAddressResult.length > 0){
        let delveryAddLat = this.businessSaleForm.controls.latitude.value || '';
        let delveryAddLng = this.businessSaleForm.controls.longitude.value || '';
        let pickUpAddLat = pickUpAddressResult[0].geometry?.location?.lat() || '';
        let pickUpAddLng = pickUpAddressResult[0].geometry?.location?.lng() || '';
        const pickUpAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
        const deliveryAddressLatLng = new google.maps.LatLng(delveryAddLat, delveryAddLng);
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        distanceMatrixService.getDistanceMatrix({
          origins: [pickUpAddressLatLng],
          destinations: [deliveryAddressLatLng],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const distance = response.rows[0].elements[0].distance?.value;
            const distanceInKm = distance / 1000;
            let valetSalary: any = 0;
            if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
              if(this.selectedExpert?.minimumKmIncluded && distanceInKm > this.selectedExpert?.minimumKmIncluded){
                let extraKm = (distanceInKm - this.selectedExpert?.minimumKmIncluded);
                valetSalary = (this.selectedExpertService?.salary) + (extraKm * Number(this.selectedExpertService?.minimumKmPrice));
              } else {
                valetSalary = this.selectedExpertService?.salary;
              }
            }
            this.businessSaleForm.controls.expertSalary?.setValue(parseFloat(valetSalary)?.toFixed(2));
            this.businessSaleForm.controls.expertServiceId.setValue(this.selectedExpertService?.id);
            if(!this.businessSaleForm.controls.payable.value){
              this.setToZeroIfNotPayable();
            }
            console.log('distanceInKm', distanceInKm);
            console.log('valetSalary', this.businessSaleForm.controls.expertSalary?.value)
            console.log('this.selectedExpertService?.service?.serviceType', this.selectedExpertService?.service?.serviceType);
          } else {
            console.error('Error calculating distance:', status);
          }
        });
      }
    }
  }
  */

  async calculateFixedPriceValetSalary() {
    try {
      if (this.selectedExpert) {
        this.businessSaleForm.controls.valetStartTime.setValue(null);
        this.businessSaleForm.controls.valetEndTime.setValue(null);
        this.showValetStartAndEndTime = false;

        let pickUpAddLat: any;
        let pickUpAddLng: any;
        let deliveryAddLat: any;
        let deliveryAddLng: any;

        if (this.businessSaleForm.controls.pickUpAddress.value) {
          const pickUpAddressResult = await this.getLatLngByAddress(this.businessSaleForm.controls.pickUpAddress.value);
          pickUpAddLat = pickUpAddressResult[0]?.geometry?.location?.lat() || '';
          pickUpAddLng = pickUpAddressResult[0]?.geometry?.location?.lng() || '';
        }

        if (this.businessSaleForm.controls.address.value) {
          const deliveryAddressResult = await this.getLatLngByAddress(this.businessSaleForm.controls.address.value);
          deliveryAddLat = deliveryAddressResult[0]?.geometry?.location?.lat() || '';
          deliveryAddLng = deliveryAddressResult[0]?.geometry?.location?.lng() || '';
        }

        if (pickUpAddLat && pickUpAddLng && deliveryAddLat && deliveryAddLng) {
          const pickUpAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
          const deliveryAddressLatLng = new google.maps.LatLng(deliveryAddLat, deliveryAddLng);
          const distanceMatrixService = new google.maps.DistanceMatrixService();

          const response: any = await new Promise((resolve, reject) => {
            distanceMatrixService.getDistanceMatrix({
              origins: [pickUpAddressLatLng],
              destinations: [deliveryAddressLatLng],
              travelMode: google.maps.TravelMode.DRIVING,
            }, (response, status) => {
              if (status === google.maps.DistanceMatrixStatus.OK) {
                resolve(response);
              } else {
                reject(status);
              }
            });
          });

          const distance = response.rows[0].elements[0].distance?.value;
          const distanceInKm = distance / 1000;
          let valetSalary: number = 0;

          if (this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary') {
            if (this.selectedExpert?.minimumKmIncluded && distanceInKm > this.selectedExpert?.minimumKmIncluded) {
              const extraKm = distanceInKm - this.selectedExpert?.minimumKmIncluded;
              valetSalary = this.selectedExpertService.salary + (extraKm * Number(this.selectedExpertService.minimumKmPrice));
            } else {
              valetSalary = this.selectedExpertService.salary;
            }
          }

          this.businessSaleForm.controls.expertSalary?.setValue(valetSalary.toFixed(2));
          this.businessSaleForm.controls.expertServiceId.setValue(this.selectedExpertService?.id);

          if (!this.businessSaleForm.controls.payable.value) {
            this.setToZeroIfNotPayable();
          }

          console.log('distanceInKm', distanceInKm);
          console.log('valetSalary', this.businessSaleForm.controls.expertSalary?.value);
          console.log('this.selectedExpertService?.service?.serviceType', this.selectedExpertService?.service?.serviceType);

        } else {
          alert("Can not calculate distance. Try again with valid address, Check both pickup address and delivery address");
          return;
        }
      }
    } catch (error) {
      console.error('Error calculating distance:', error);
      alert("Can not calculate distance. Try again with valid address, Check both pickup address and delivery address");
    }
  }

  async getLatLngByAddress(address: string){
    const geocoder = new google.maps.Geocoder();
    const results = await new Promise<any>((resolve, reject) => {
      geocoder.geocode({ 'address': address }, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
    return results;
  }

  calculateHourlyRateValetSalary(){
    if(this.selectedExpert){
      this.showValetStartAndEndTime = true;
      this.businessSaleForm.controls.valetStartTime.setValue(this.previousValetTimeValues.valetStartTime);
      this.businessSaleForm.controls.valetEndTime.setValue(this.previousValetTimeValues.valetEndTime);
      let valetSalary = (this.totalValetTimeDifference * this.selectedExpertService?.salary)?.toFixed(2);
      this.businessSaleForm.controls.expertSalary.setValue(valetSalary);
      this.businessSaleForm.controls.expertServiceId.setValue(this.selectedExpertService?.id);
      if(!this.businessSaleForm.controls.payable.value){
        this.setToZeroIfNotPayable();
      }
      console.log('valetSalary', this.businessSaleForm.controls.expertSalary?.value)
      console.log('this.selectedExpertService?.service?.serviceType', this.selectedExpertService?.service?.serviceType);
    }
  }

  setToZeroIfNotPayable(){
    this.businessSaleForm.controls.expertSalary.setValue(0);
    this.businessSaleForm.controls.valetAdditionalPrice.setValue(0);
  }

  addProduct() {
    const control = <FormArray>this.businessSaleForm.get('deliveryProducts');
    control.push(Delivery.productFormGroup());
  }

  removeProduct(index: number) {
    const control = <FormArray>this.businessSaleForm.get('deliveryProducts');
    this.showProductImage[index] = false;
    this.productImageUrls[index] = '';
    control.removeAt(index);
    this.setProductValue();
  }

  onSubmit(){
    this.tmpBtnDisabled = true;
    let delivery: any = (new FormGroupToObject()).transform(this.businessSaleForm);
    delivery.saleId = this.saleData.orderId;
    delivery.orderId = this.saleData.actualOrderId;
    delivery.realOrderNumber = this.saleData?.realOrderNumber;
    delivery.shop = 'BusinessSales';
    delivery.customer = null;
    let ddtfile = null;
    if(delivery.ddtFile){
      ddtfile = delivery.ddtFile;
      delete delivery.ddtFile;
    }
    let promiseResult = this.deliveryService.createDeliveryViaShopifyOrder(delivery);

    promiseResult.then(async (data: Delivery) => {
      this.saleData = data;
      if(ddtfile){
        this.saleData = await this.uploadDdt(this.saleData.id).catch((err) => {
          this.errorMessage(`Errore nella 'creazione della' consegna`);
        });
      }
      this.successMessage(`Consegna creata con successo. ${ + this.saleData.id} `);
      this.dialogRef.close({ delivery: this.saleData });
    }).catch((err: any) => {
      this.errorMessage(`Errore nella 'creazione della' consegna`);
    })
  }

  uploadDdt(deliveryId: number) {
    return this.deliveryService.updateDeliveryDdt(
      deliveryId,
      this.businessSaleForm.get('ddtFile').value
    );
  }

  onFileSelect(event: any){
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.businessSaleForm.get('ddtFile').setValue(file);
    }
  }

  setTime(timePeriod){
    if (this.timePeriods[timePeriod]) {
      this.businessSaleForm.controls.fromTime.setValue(this.timePeriods[timePeriod]['from']);
      this.businessSaleForm.controls.toTime.setValue(this.timePeriods[timePeriod]['to']);
    }
    else {
      this.businessSaleForm.controls.fromTime.setValue('');
      this.businessSaleForm.controls.toTime.setValue('');
    }
  }

  setDeliveryStatus(idExpert){
    this.businessSaleForm.controls.status.setValue(this.deliveryStatus.assigned);
    this.selectedExpert = this.experts.find((expert) => expert?.id == idExpert);
    this.selectedExpertServices = this.selectedExpert?.expertServices;
    this.expertServiceAndSalary();
  }

  getAddress(place: any) {
    if (!this.businessSaleForm) return;
    this.businessSaleForm.controls.address.touched = true;
    this.businessSaleForm.controls.address.setValue(place.formatted_address || place.name || '');
    this.businessSaleForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this.businessSaleForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
    let province: string = findProvinceFromAddress(place);
    this.businessSaleForm.controls.province.setValue(province);

    if((this.selectedService?.service?.pricingModel == 'fixedprice') || (this.selectedService?.service?.pricingModel.toLowerCase() == 'corporate')){
      this.geocodePickupAddress(this.businessSaleForm.controls.pickUpAddress.value);
    }

    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }
  }

  directions(event:any){
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this.businessSaleForm.controls.address.value}`, '_blank');
  }

  handlePartnerChange(partnerId: any){
    if(this.partners){
      this.selectedPartner = this.partners.find((partner) => {
        return partner.id == partnerId;
      });
    }
    this.selectedPartnerServices = this.selectedPartner?.partnerServices;
    let pickUpAddress = (this.selectedPartner ? this.selectedPartner?.address : '');
    this.businessSaleForm.controls.pickUpAddress.setValue(pickUpAddress);
    if (this.selectedPartnerServices) {
      this.selectedService = this.selectedPartnerServices.map(service => {
          return { label: service.name, value: service.id };
        });
    }
  }

  handlePartnerChangeDropDown(partnerId: any){
    this.businessSaleForm.controls.service.setValue(null);
    this.reset();
    if(this.partners){
      this.selectedPartner = this.partners.find((partner) => {
        return partner.id == partnerId;
      });
    }
    this.selectedPartnerServices = this.selectedPartner?.partnerServices;
    let pickUpAddress = (this.selectedPartner ? this.selectedPartner?.address : '');
    this.businessSaleForm.controls.pickUpAddress.setValue(pickUpAddress);
    if (this.selectedPartnerServices) {
      this.selectedService = this.selectedPartnerServices.map(service => {
          return { label: service.name, value: service.id };
        });
    }

  }

  reset(){
    this.businessSaleForm.controls.pickUpAddress.setValue(null);
    this.businessSaleForm.controls.startTime.setValue(null);
    this.businessSaleForm.controls.distance.setValue(null);
    this.businessSaleForm.controls.endTime.setValue(null);
    this.businessSaleForm.controls.price?.setValue(null);
    this.businessSaleForm.controls.productValue?.setValue(null);
    this.businessSaleForm.controls.service?.setValue(null);
    this.businessSaleForm.controls.hours.setValue(0);
    this.businessSaleForm.controls.requestExpert.setValue(false);
    this.businessSaleForm.controls.deluxyDelivery.setValue(false);
    this.showStartEndTime = false;
    this.showProductValue = false;
    this.showPickUpAddress= false;
    this.showDistanceInput= false;
  }

  async geocodePickupAddress(pickUpAddress: string) {
    try {
      const geocoder = new google.maps.Geocoder();
      const results = await new Promise<any>((resolve, reject) => {
        geocoder.geocode({ 'address': pickUpAddress }, (results, status) => {
          if (status === 'OK') {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });

      if (results && results.length > 0) {
        this.pickUpAddressLat = results[0].geometry?.location?.lat() || '';
        this.pickUpAddressLng = results[0].geometry?.location?.lng() || '';
        let deliveryAddLat: any;
        let deliveryAddLng: any;

        if (this.businessSaleForm.controls.address.value) {
          const deliveryAddressResult = await this.getLatLngByAddress(this.businessSaleForm.controls.address.value);
          deliveryAddLat = deliveryAddressResult[0]?.geometry?.location?.lat() || '';
          deliveryAddLng = deliveryAddressResult[0]?.geometry?.location?.lng() || '';
        }

        const fixedAddressLatLng = new google.maps.LatLng(this.pickUpAddressLat, this.pickUpAddressLng);
        const selectedAddressLatLng = new google.maps.LatLng(deliveryAddLat, deliveryAddLng);
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        if (this.pickUpAddressLat && this.pickUpAddressLng && deliveryAddLat && deliveryAddLng) {
          const response: any = await new Promise((resolve, reject) => {
            distanceMatrixService.getDistanceMatrix({
              origins: [fixedAddressLatLng],
              destinations: [selectedAddressLatLng],
              travelMode: google.maps.TravelMode.DRIVING,
            }, (response, status) => {
              if (status === google.maps.DistanceMatrixStatus.OK) {
                resolve(response);
              } else {
                reject(status);
              }
            });
          });

          const distance = response.rows[0].elements[0].distance?.value;
          const distanceInKm = distance / 1000;
          console.log('getAddressdistanceInKm', distanceInKm)
          this.businessSaleForm.controls.distance.setValue(distanceInKm.toFixed(2));
          this.handleDistanceChange(distanceInKm);
        } else {
          alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
          return;
        }
      } else {
        alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
        return;
      }
    } catch (error) {
      console.log('Error', error.message);
      alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
      return;
    }
  }

  /*
  getPickUpAddress(place: any) {
    if(this.selectedService?.service?.pricingModel === 'fixedprice'){
      try {
        if (!this.businessSaleForm) return;
        this.businessSaleForm.controls.pickUpAddress.touched = true;
        this.businessSaleForm.controls.pickUpAddress.setValue(place.formatted_address || place.name || '');
        let lat = place.geometry?.location?.lat() || '';
        let lng = place.geometry?.location?.lng() || '';
        this.pickUpAddressLat = lat;
        this.pickUpAddressLng = lng;
        let fixedAddressLatLng = new google.maps.LatLng(lat, lng);
        let selectedAddressLatLng = new google.maps.LatLng(this.businessSaleForm.controls.latitude.value, this.businessSaleForm.controls.longitude.value)
        const distanceMatrixService = new google.maps.DistanceMatrixService();
        distanceMatrixService.getDistanceMatrix({
          origins: [fixedAddressLatLng],
          destinations: [selectedAddressLatLng],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const distance = response.rows[0].elements[0].distance?.value;
            const distanceInKm = distance / 1000;
            this.businessSaleForm.controls.distance.setValue(distanceInKm.toFixed(2));
            this.handleDistanceChange(distanceInKm);
          } else {
            console.error('Error calculating distance:', status);
          }
        });
      } catch (error) {
        console.log('Error', error.message)
      }
    }else {
      this.businessSaleForm.controls.pickUpAddress.setValue(place.formatted_address || place.name || '');
    }
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }
  }
  */

  async getPickUpAddress(place: any) {
    if(this.selectedService?.service?.pricingModel === 'fixedprice'){
      try {
        if (!this.businessSaleForm) return;
        this.businessSaleForm.controls.pickUpAddress.touched = true;
        this.businessSaleForm.controls.pickUpAddress.setValue(place.formatted_address || place.name || '');
        let pickUpAddLat: any;
        let pickUpAddLng: any;
        let deliveryAddLat: any;
        let deliveryAddLng: any;

        if (this.businessSaleForm.controls.pickUpAddress.value) {
          const pickUpAddressResult = await this.getLatLngByAddress(this.businessSaleForm.controls.pickUpAddress.value);
          pickUpAddLat = pickUpAddressResult[0]?.geometry?.location?.lat() || '';
          pickUpAddLng = pickUpAddressResult[0]?.geometry?.location?.lng() || '';
          this.pickUpAddressLat = pickUpAddLat;
          this.pickUpAddressLng = pickUpAddLng;
        }

        if (this.businessSaleForm.controls.address.value) {
          const deliveryAddressResult = await this.getLatLngByAddress(this.businessSaleForm.controls.address.value);
          deliveryAddLat = deliveryAddressResult[0]?.geometry?.location?.lat() || '';
          deliveryAddLng = deliveryAddressResult[0]?.geometry?.location?.lng() || '';
        }

        if (pickUpAddLat && pickUpAddLng && deliveryAddLat && deliveryAddLng) {
          const pickUpAddressLatLng = new google.maps.LatLng(pickUpAddLat, pickUpAddLng);
          const deliveryAddressLatLng = new google.maps.LatLng(deliveryAddLat, deliveryAddLng);
          const distanceMatrixService = new google.maps.DistanceMatrixService();

          const response: any = await new Promise((resolve, reject) => {
            distanceMatrixService.getDistanceMatrix({
              origins: [pickUpAddressLatLng],
              destinations: [deliveryAddressLatLng],
              travelMode: google.maps.TravelMode.DRIVING,
            }, (response, status) => {
              if (status === google.maps.DistanceMatrixStatus.OK) {
                resolve(response);
              } else {
                reject(status);
              }
            });
          });

          const distance = response.rows[0].elements[0].distance?.value;
          const distanceInKm = distance / 1000;
          console.log('distanceInKm', distanceInKm)
          this.businessSaleForm.controls.distance.setValue(distanceInKm.toFixed(2));
          this.handleDistanceChange(distanceInKm);
        } else {
          alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
          return;
        }
      } catch (error) {
        console.log('Error', error.message)
        alert("Can not calculate distance. Try again with valid address, Check both pickupaddress and delivery address");
        return;
      }
    } else {
      this.businessSaleForm.controls.pickUpAddress.setValue(place.formatted_address || place.name || '');
    }
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }
  }

  handleDistanceChange(distance: any){
    let price: any = 0;
    if((this.selectedService?.service?.pricingModel?.toLowerCase() === 'fixedprice') || (this.selectedService?.service?.pricingModel?.toLowerCase() === 'corporate')){
      if(distance > this.selectedPartner?.kmIncluded && this.selectedPartner?.kmIncluded){
        let extraKm = (distance - this.selectedPartner?.kmIncluded);
        price = parseFloat(this.selectedService?.price) + (extraKm * this.selectedService?.extraKmPrice);
      } else {
        price = parseFloat(this.selectedService?.price)
      }
    }
    this.businessSaleForm.controls.price?.setValue(parseFloat(price)?.toFixed(2));
    this.businessSaleForm.controls.distance?.setValue(distance.toFixed(2));
  }

  pickUpDirections(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this.businessSaleForm.controls.pickUpAddress.value}`, '_blank');
  }

  handleStartTimeChange(value: string) {
    this.businessSaleForm.controls.startTime.setValue(value);
    const startTime = new Date('1970-01-01T' + this.businessSaleForm.value?.startTime + ':00');
    const endTime = new Date(startTime.getTime() + 3600000);
    const endTimeString = this.formatTime(endTime);
    this.businessSaleForm.controls.endTime.setValue(endTimeString);
    this.businessSaleForm.controls.fromTime.setValue(value);
    this.businessSaleForm.controls.toTime.setValue(endTimeString);
    this.calculateTimeDifference();
  }

  calculateTimeDifference() {
    const startTime = new Date('1970-01-01T' + this.businessSaleForm.value?.startTime + ':00');
    const endTime = new Date('1970-01-01T' + this.businessSaleForm.value?.endTime + ':00');
    const timeDiff = endTime.getTime() - startTime.getTime();
    const hours = Math.floor(timeDiff / 3600000);
    if(hours <= 0){
      alert('Minimum one hour is required');
      this.businessSaleForm.controls.startTime.setValue(this.previousValues.startTime);
      this.businessSaleForm.controls.endTime.setValue(this.previousValues.endTime);
      this.businessSaleForm.controls.fromTime.setValue(this.previousValues.startTime);
      this.businessSaleForm.controls.toTime.setValue(this.previousValues.endTime);
      this.businessSaleForm.controls.valetStartTime.setValue(this.previousValues.startTime);
      this.businessSaleForm.controls.valetEndTime.setValue(this.previousValues.endTime);
      return false;
    }
    const minutes = Math.floor((timeDiff % 3600000) / 60000);
    console.log(`Time Difference: ${hours} hours ${minutes} minutes`);
    // let totalTime = `${hours}.${minutes}`;
    const totalTime = hours + minutes / 60;
    this.totalDeliveryTime = totalTime.toFixed(2);
    this.totalValetTimeDifference = totalTime.toFixed(2);
    let price = hours !== 0 ? Number(this.selectedService?.price) * Number(this.totalDeliveryTime) : Number(this.selectedService?.price)
    this.businessSaleForm.controls.price.setValue(price?.toFixed(2));
    this.businessSaleForm.controls.hours.setValue(this.totalDeliveryTime);
    this.previousValues.startTime = this.businessSaleForm.value.startTime;
    this.previousValues.endTime = this.businessSaleForm.value.endTime;
    this.previousValetTimeValues.valetStartTime = this.businessSaleForm.value.startTime;
    this.previousValetTimeValues.valetEndTime = this.businessSaleForm.value.endTime;
    this.expertServiceAndSalary();
  }

  formatTime(date: Date): string {
    return date.toTimeString().slice(0, 5); // Format as HH:MM
  }

  handleEndTimeChange(value: string) {
    this.businessSaleForm.controls.endTime.setValue(value);
    this.businessSaleForm.controls.toTime.setValue(value);
    this.calculateTimeDifference();
  }

  handleProductChange(index?: number, item?: any){
    let selctedProduct = this.products.find(product => product?.id == item?.id);
    this.showProductImage[index] = selctedProduct?.image ? true : false;
    this.productImageUrls[index] = selctedProduct?.image ? selctedProduct.image : '';
    this.setProductValue();
  }

  handleProductQuantityChange(){
    this.setProductValue();
  }

  handlePickUpFlexbleTime(){
    if(this.pickUpFTime && this.pickUpTTime){
      let time = this.pickUpFTime + "-" + this.pickUpTTime;
      this.businessSaleForm.controls.pickUpTime?.setValue(time);
    }
  }

  handleBillable(event: any){
    this.businessSaleForm.controls.bilable?.setValue(event.target.checked);
    this.checkDeliveryIsBillable();
  }

  checkDeliveryIsBillable(){
    if(!this.businessSaleForm.controls.billable.value){    // Delivery is not billable
      this.businessSaleForm.controls.price.setValue(0);
      this.businessSaleForm.controls.additionalPrice.setValue(0);
    }
    this.disableAdditionalPriceInput = !this.businessSaleForm.controls.billable.value;
  }

  handlePayable(event: any){
    this.businessSaleForm.controls.bilable?.setValue(event.target.checked);
    this.checkDeliveryIsPayable();
  }

  checkDeliveryIsPayable(){
    if(!this.businessSaleForm.controls.payable.value){    // Delivery is not payable
      this.setToZeroIfNotPayable();
    }
    this.disableValetAdditionalPriceInput = !this.businessSaleForm.controls.payable.value;
  }

  handleValetServiceChange(valetServiceId: number){
    this.selectedExpertService = this.selectedExpertServices.find(expertService => expertService?.id == valetServiceId);
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'fixedpricesalary'){
      this.calculateFixedPriceValetSalary();
    }
    if(this.selectedExpertService?.service?.serviceType?.toLowerCase() === 'hourlyratesalary'){
      this.calculateHourlyRateValetSalary();
    }
  }

  handleValetStartTimeChange(value: string){
    this.businessSaleForm.controls.valetStartTime.setValue(value);
    const startTime = new Date('1970-01-01T' + this.businessSaleForm.controls.valetStartTime.value + ':00');
    const endTime = new Date(startTime.getTime() + 3600000);
    const endTimeString = this.formatTime(endTime);
    this.businessSaleForm.controls.valetEndTime.setValue(endTimeString);
    this.calculateValetTimeDifference();
  }

  handleValetEndTimeChange(value: string){
    this.businessSaleForm.controls.valetEndTime.setValue(value);
    this.calculateValetTimeDifference();
  }

  calculateValetTimeDifference() {
    const startTime = new Date('1970-01-01T' + this.businessSaleForm.controls.valetStartTime.value + ':00');
    const endTime = new Date('1970-01-01T' + this.businessSaleForm.controls.valetEndTime.value + ':00');
    const timeDiff = endTime.getTime() - startTime.getTime();
    const hours = Math.floor(timeDiff / 3600000);
    if(hours <= 0){
      alert('Minimum one hour is required');
      this.businessSaleForm.controls.valetStartTime.setValue(this.previousValetTimeValues.valetStartTime);
      this.businessSaleForm.controls.valetEndTime.setValue(this.previousValetTimeValues.valetEndTime);
      return false;
    }
    const minutes = Math.floor((timeDiff % 3600000) / 60000);
    // let totalTime = `${hours}.${minutes}`;
    const totalTime = hours + minutes / 60;
    this.totalValetTimeDifference = totalTime.toFixed(2);
    console.log('this.totalValetTimeDifference', this.totalValetTimeDifference)
    this.previousValetTimeValues.valetStartTime = this.businessSaleForm.controls.valetStartTime.value;
    this.previousValetTimeValues.valetEndTime = this.businessSaleForm.controls.valetEndTime.value;
    this.calculateHourlyRateValetSalary();
  }

  async getAllActivePartnersByProvinceId(provinceId: number|string){
    let data = await this.partnerService.getAllActivePartnersByProvinceId(provinceId);
  }

  errorMessage(msg: string){
    this._snackBar.open(`${msg}`,
      'Chiudi',
      {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }
    );
  }

  isOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const products of this.businessSaleForm.value.deliveryProducts) {
      if (products.product.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId; // assuming your item has a 'disabled' property
  }

  successMessage(msg: string){
    this._snackBar.open(`${msg}`,
      'Chiudi',
      {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }
    );
  }

}
