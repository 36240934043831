import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';
import { ExpertService } from 'src/app/services/expert.service';

@Injectable()
export class ActivityRedirection implements CanActivate {

    isTeamLeader: boolean = false;
    constructor(
        private authService: AuthStatusService,
        private router: Router,
        public jwtHelper: JwtHelperService,
        private expertService: ExpertService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isAuthenticated: boolean = await this.authService.isAuthenticated();
        if (!isAuthenticated) {
            this.router.navigate(['/login']);
            return false;
        }
        const loggedInUserInfo = await this.authService.getTokenInfo();
        const currentUrl = state.url;

        if (loggedInUserInfo.roles.includes('expert')) {
            const loggedInExpert = await this.expertService.getOne(loggedInUserInfo['extraId']);
            const isTeamLeader = loggedInExpert?.isTeamLeader;

            if (!isTeamLeader && !currentUrl.startsWith('/activities/own')) {
                this.router.navigate(['/activities/own']);
                return false;
            }

            if(isTeamLeader && !currentUrl.startsWith('/activities/own')){
                this.router.navigate(['/activities/own']);
                return false;
            }
        }

        if (loggedInUserInfo.roles.includes('admin') || loggedInUserInfo.roles.includes('operation')) {
            if (!currentUrl.startsWith('/activities/other/valet')) {
                this.router.navigate(['/activities/other/valet']);
                return false;
            }
        }

        return true; // Allow access if no redirection condition matches
    }
}
