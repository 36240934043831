import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliveryService } from 'src/app/services/delivery.service';
import { CommanModalComponent } from '../comman-modal/comman-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-approve-timings',
  templateUrl: './approve-timings.component.html',
  styleUrls: ['./approve-timings.component.scss']
})
/*
export class ApproveTimingsComponent implements OnInit {

  delivery: any;
  pickUpFTime: any;
  pickUpTTime: any;
  pickUpFTimeError: boolean = false;
  pickUpTTimeError: boolean = false;
  btnDisabled: boolean = false;
  timingForm: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CommanModalComponent>,
    private deliveryService: DeliveryService,
  ) {
    this.delivery = data.delivery;
    this.manageTimingForm();
  }

  async ngOnInit() {
    try {
      this.patch();
      this.timingForm.get('approvedTimingStatus').valueChanges.subscribe((isChecked) => {
        const newPickUpTimeControl = this.timingForm.get('newPickUpTime');
        if (!isChecked) {
          newPickUpTimeControl.setValidators([Validators.required]);
        } else {
          newPickUpTimeControl.clearValidators();
        }
        newPickUpTimeControl.updateValueAndValidity();
      });
    } catch (error) {
      
    }
  }

  manageTimingForm(){
    this.timingForm = new FormGroup({
      'pickUpTime': new FormControl(''),
      'newPickUpTime': new FormControl(''),
      'approvedTimingStatus': new FormControl(true),
    });
  }

  onCheckboxChange() {
    const isChecked = this.timingForm.controls.approvedTimingStatus.value;
    if(isChecked){
      this.timingForm.controls.newPickUpTime.setValue('');
    }
  }

  patch(){
    this.timingForm.patchValue({
      pickUpTime: this.delivery.pickUpTime
    });
  }

  handlePickUpFlexbleTime(){
    this.pickUpFTimeError = !this.pickUpFTime;
    this.pickUpTTimeError = !this.pickUpTTime;
    if(this.pickUpFTime && this.pickUpTTime){
      let time = this.pickUpFTime + "-" + this.pickUpTTime;
      this.timingForm.controls.newPickUpTime?.setValue(time);
    }
  }
  
  async onSubmit(){
    try {
      if(this.timingForm.valid){
        let promiseResult = await this.deliveryService.approveTimings(this.delivery.id, this.timingForm.value);
        if(promiseResult){
          this.dialogRef.close(true);
        }
      }
    } catch (error) {
      console.log("ERROR IN SUBMITTING FORM", error);
    }
  }

}
*/

export class ApproveTimingsComponent implements OnInit {

  valetStartEndTimeForm: any;
  delivery: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CommanModalComponent>,
    private deliveryService: DeliveryService,
    private _snackBar: MatSnackBar,
  ){ 
    this.delivery = data.delivery;
    this.manageValetStartEndTime();
  }

  async ngOnInit() {
    
  }

  manageValetStartEndTime(){
    this.valetStartEndTimeForm = new FormGroup({
      'valetStartTime': new FormControl(this.delivery.valetStartTime),
      'valetEndTime': new FormControl(this.delivery.valetEndTime),
    });
  }

  async onSubmit(){
    try {
      if(this.valetStartEndTimeForm.valid){
        let promiseResult = await this.deliveryService.updateValetStartEndTime(this.delivery.id, this.valetStartEndTimeForm.value);
        if(promiseResult){
          this.dialogRef.close({
            success: true
          });
        }
      }
    } catch (error) {
      this._snackBar.open("Something went wrong", 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }
  }

}
