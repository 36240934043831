import { Injectable } from '@angular/core';
import { Delivery, DeliveryStatus, DeliveryStatusAux } from '../models/delivery.schema';
declare var google: any;
@Injectable({
  providedIn: 'root',
})
export class MapHelperService {
  initializeMap(mapId: string, center: google.maps.LatLngLiteral, zoom: number): google.maps.Map {
    return new google.maps.Map(document.getElementById(mapId) as HTMLElement, {
      center,
      zoom,
      mapId: "valetActivitiesMap12"
    });
  }

  async calculateAndDisplayRoute(
    map: google.maps.Map,
    origin: string,
    destination: string,
    waypoints: google.maps.DirectionsWaypoint[],
    optimize: boolean
  ): Promise<google.maps.DirectionsResult> {
    const directionsService = new google.maps.DirectionsService();
    // const directionsRenderer = new google.maps.DirectionsRenderer({ map });
    const directionsRenderer = new google.maps.DirectionsRenderer({ map, suppressMarkers: true });

    return new Promise((resolve, reject) => {
      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          travelMode: google.maps.TravelMode.DRIVING,
          optimizeWaypoints: optimize,
        },
        (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(response);
            resolve(response);
          } else {
            reject(`${status}`);
          }
        }
      );
    });
  }

  calculateTimeAndDistance(legs: google.maps.DirectionsLeg[]): { distance: string; duration: string } {
    const totalDistance = legs.reduce((sum, leg) => sum + leg.distance.value, 0);
    const totalDuration = legs.reduce((sum, leg) => sum + leg.duration.value, 0);

    return {
      distance: `${(totalDistance / 1000).toFixed(2)} km`,
      duration: `${Math.floor(totalDuration / 3600)}h ${Math.floor((totalDuration % 3600) / 60)}m`,
    };
  }

  addPinsWithActivities(
    map: google.maps.Map,
    actAddrArray: any[]
  ): void {
    // const markers: google.maps.Marker[] = [];
    const markers: any[] = [];
    const geocoder = new google.maps.Geocoder();
    const markerAddressMap: { [key: string]: any[] } = {};
  
    actAddrArray.forEach((item, index) => {
      let address = item.address;
      geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          const markerPosition = results[0].geometry.location;
          const positionKey = `${markerPosition.lat()},${markerPosition.lng()}`;
          const activity = item.activity;
          let backgroundColor = "#ffffff"; // Default color
          if (activity.activityType === "delivery") backgroundColor = "#007BFF";
          else if (activity.activityType === "pickup") backgroundColor = "#FFC107";
          else if (activity.activityType === "hourlyservice") backgroundColor = "#28A745";
          const pinElement = new google.maps.marker.PinElement({
            scale: 1.2, // Scale the pin size
            glyph: `${index + 1}`, // Label for the pin
            background: backgroundColor, // Background color
            borderColor: "#000000", // Border color
          });
  
          const advancedMarker = new google.maps.marker.AdvancedMarkerElement({
            position: markerPosition,
            content: pinElement.element, // PinElement's HTML element
            title: `Stop ${index + 1}: ${address}`,
            map: map,
          });
  
          // Associate marker with activities
          if (!markerAddressMap[positionKey]) {
            markerAddressMap[positionKey] = [];
          }
          markerAddressMap[positionKey].push(actAddrArray[index]);
  
          // Add info window on marker click
          advancedMarker.addListener("click", () => {
            const activitiesForPin = markerAddressMap[positionKey];
            // const infoWindowContent = activitiesForPin
            //   .map(
            //     (actAddr) => `
            //     <div>
            //       <strong>Activity ${actAddr.activity.order}</strong><br>
            //       Type: ${actAddr.activity.activityType.toUpperCase()}<br>
            //       Status: ${actAddr.activity.delivery.status}<br>
            //       DeliveryId: ${actAddr.activity.delivery.id}<br>
            //       Address: ${
            //         actAddr.activity.activityType === 'pickup' ? actAddr.activity.delivery.pickUpAddress : actAddr.activity.delivery.address
            //       }
            //     </div>
            //   `
            //   )
            //   .join("<br>");
            const infoWindowContent = activitiesForPin
              .map(
                (actAddr) => `
                <div>
                  <strong>Activity ${actAddr.activity.order}</strong><br>
                  Type: ${actAddr.activity.activityType.toUpperCase()}<br>
                  Status: ${actAddr.activity.delivery.status}<br>
                  DeliveryId: ${actAddr.activity.delivery.id}<br>
                  Address: ${this.getActivityAddress(actAddr.activity)}
                </div>
              `
              )
              .join("<br>");
  
            const infoWindow = new google.maps.InfoWindow({
              content: infoWindowContent,
            });
            infoWindow.open(map, advancedMarker);
          });
  
          // Handle overlapping markers
          let filtered = markers.filter((marker) => {
            return marker.position.Fg === markerPosition.lat() && marker.position.Hg === markerPosition.lng()
          });
  
          let offset = filtered.length
  
          if (offset > 0) {
            const latOffset = 0.0002 * (offset % 2 === 0 ? 1 : -1);
            const lngOffset = 0.0002 * (offset % 2 === 0 ? 1 : -1);
            advancedMarker.position = new google.maps.LatLng(
                markerPosition.lat() + latOffset,
                markerPosition.lng() + lngOffset
              )
  
          }
  
          markers.push(advancedMarker);
        } else {
          console.error(`Geocoding failed for ${address}: ${status}`);
        }
      });
    });
  
    // Adjust map bounds to fit markers
    // if (markers.length > 1) {
    //   const bounds = new google.maps.LatLngBounds();
    //   markers.forEach((marker) => bounds.extend(marker.getPosition()));
    //   map.fitBounds(bounds);
    // } else if (markers.length === 1) {
    //   map.setCenter(markers[0].getPosition());
    //   map.setZoom(14);
    // }
  }

  getActivityAddress(activity: any): string {
    const { activityType, delivery } = activity;
  
    if (activityType === 'pickup') {
      return delivery.pickUpAddress;
    } else if (activityType === 'delivery') {
      return delivery.address;
    } else if (
      activityType === 'hourlyservice' &&
      delivery.status === DeliveryStatus.assigned
    ) {
      return delivery.pickUpAddress;
    } else if (
      activityType === 'hourlyservice' &&
      delivery.status === DeliveryStatus.delivering
    ) {
      return delivery.address;
    }
  
    // Default case
    return delivery.address;
  }  

  async plotPinsOnMap(map: google.maps.Map, deliveries: Delivery[]) {
    const geocoder = new google.maps.Geocoder();
    const markerAddressMap: { [key: string]: any[] } = {};
    const markers: any[] = [];

    const geocodePromises = deliveries.map((item, index) => {
        return new Promise<void>((resolve, reject) => {
            geocoder.geocode({ address: item.address }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK && results[0]) {
                    const markerPosition = results[0].geometry.location;
                    const positionKey = `${markerPosition.lat()},${markerPosition.lng()}`;

                    // Count how many deliveries are at the same position
                    const deliveryCount = markerAddressMap[positionKey] ? markerAddressMap[positionKey].length : 0;
                    markerAddressMap[positionKey] = [...(markerAddressMap[positionKey] || []), item];

                    // Create custom HTML content for displaying the delivery count on top of the default pin
                    const pinContent = document.createElement('div');
                    pinContent.style.position = 'relative';
                    pinContent.style.display = 'inline-block';

                    // Increase the pin size (default size was 30px, let's make it larger)
                    const pinBackground = document.createElement('div');
                    pinBackground.style.width = '50px';  // Increased size
                    pinBackground.style.height = '50px'; // Increased size
                    pinBackground.style.backgroundImage = 'url(http://maps.google.com/mapfiles/ms/icons/red-dot.png)';
                    pinBackground.style.backgroundSize = 'cover';
                    pinBackground.style.borderRadius = '50%';

                    // Delivery count on top of the pin
                    const countBadge = document.createElement('div');
                    countBadge.style.position = 'absolute';
                    countBadge.style.top = '-5px';
                    countBadge.style.left = '50%';
                    countBadge.style.transform = 'translateX(-50%)';
                    countBadge.style.backgroundColor = 'red';
                    countBadge.style.color = '#fff';
                    countBadge.style.padding = '5px 7px';  // Increased padding for better readability
                    countBadge.style.borderRadius = '50%';
                    countBadge.style.fontSize = '14px';   // Increased font size for better visibility
                    countBadge.style.fontWeight = 'bold';
                    countBadge.style.textAlign = 'center';
                    countBadge.textContent = `${deliveryCount + 1}`;  // Increment the count for each new delivery at this location

                    // Append the background and the badge
                    pinContent.appendChild(pinBackground);
                    pinContent.appendChild(countBadge);

                    // Create an AdvancedMarkerElement with the custom pin content
                    const advancedMarker = new google.maps.marker.AdvancedMarkerElement({
                        position: markerPosition,
                        title: `Stop ${index + 1}: ${item.address}`,
                        map: map,
                        content: pinContent,  // Set the custom HTML as the marker content
                    });

                    // Add click listener to show InfoWindow with delivery details
                    advancedMarker.addListener("click", () => {
                        const activitiesForPin = markerAddressMap[positionKey];
                        const infoWindowContent = activitiesForPin
                            .map(delivery => `<div>DeliveryId: ${delivery.id}<br>Address: ${delivery.address}</div>`)
                            .join("<br>");
                        const infoWindow = new google.maps.InfoWindow({
                            content: infoWindowContent,
                        });
                        infoWindow.open(map, advancedMarker);
                    });

                    markers.push(advancedMarker);
                    resolve();
                } else {
                    console.error(`Geocoding failed for ${item.address}: ${status}`);
                    reject(new Error(`Geocoding failed for ${item.address}: ${status}`));
                }
            });
        });
    });

    try {
        await Promise.all(geocodePromises);

        if (markers.length > 1) {
            const bounds = new google.maps.LatLngBounds();
            markers.forEach(marker => bounds.extend(marker.position));
            map.fitBounds(bounds);
        } else if (markers.length === 1) {
            map.setCenter(markers[0].position);
            map.setZoom(14);
        }
    } catch (error) {
        console.error("Error processing geocoding:", error);
    }
}

  
  
  

  async geoCodeAddress(address: string) {
    const geocoder = new google.maps.Geocoder();
    try {
      const results = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });
      
      return results;
    } catch (error) {
      console.error(`Geocode failed for address "${address}" with error:`, error);
      throw error;
    }
  }
  
}
