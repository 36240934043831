import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpertService } from 'src/app/services/expert.service';
import { SetAvailabilityTimeComponent } from '../set-availability-time/set-availability-time.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { Expert, ExpertProvinces, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { UserService } from 'src/app/services/user.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-expert-availability',
  templateUrl: './expert-availability.component.html',
  styleUrls: ['./expert-availability.component.scss']
})
export class ExpertAvailabilityComponent implements OnInit {

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    // dateClick: this.handleDateClick.bind(this),
    datesSet: this.onDatesSet.bind(this),
  };
  expertId: number|string = null;
  expert: any;
  Events: any[] = [];
  eventsLoaded: boolean = false;
  bulkAddForm: any;
  bulkEditForm: any;
  bulkEditAvailabilityTimeForm: any;
  allDateTimeforEdit: { [key: string]: any[] } = {}; // Initialize to an empty object
  experts: Expert[] = [];
  allDaysCheck: boolean = true;
  monToFriCheck: boolean = false;
  weekendCheck: boolean = false;
  bulkAddHanldle: boolean = false;
  bulkEditHanldle: boolean = false;
  dateRangeObject: { fromDate: string; toDate: string };
  days: { available: boolean, selected: boolean, startTime: string, endTime: string }[] = [
    { available: false, selected: false, startTime: '', endTime: '' },
    { available: false, selected: false, startTime: '', endTime: '' },
    { available: false, selected: false, startTime: '', endTime: '' },
    { available: false, selected: false, startTime: '', endTime: '' },
    { available: false, selected: false, startTime: '', endTime: '' },
    { available: false, selected: false, startTime: '', endTime: '' },
    { available: false, selected: false, startTime: '', endTime: '' }
  ];
  weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  groups: string[];
  user: any;
  loggedInUserInfo: any;
  loggedInExpert: Expert;
  minDate: string;
  minEndDate: string = '';
  currentYearStart: string = '';
  currentYearEnd: string = '';
  btnDisable: boolean = false;
  removeAvailabilityForm: any;
  removeAvaHandle: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private expertService: ExpertService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private authStatusService: AuthStatusService,
    private userService: UserService,
    private spinnerService: SpinnerService,
  ) {
    this.expertId = this.route.snapshot.paramMap.get('id');
    this.handleAddBulkForm();
    this.handleSearchBulkForm();
    this.handleEditAvailabilityTimeBulkForm();
    this.handleRemoveAvailabilityForm();
    this.SetMinDate();
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async SetMinDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.minDate = `${year}-${month}-${day}`;
    this.currentYearStart = `${year}-01-01`;
    this.currentYearEnd = `${year}-12-31`;
  }
  async onStartDateChange() {
    const startDate = this.bulkAddForm.get('startDate')?.value;
    if (startDate) {
      this.minEndDate = startDate; // Set the `min` attribute of the `endDate` input
      // Optional: Reset `endDate` if it’s earlier than `startDate`
      const endDate = this.bulkAddForm.get('endDate')?.value;
      if (endDate && new Date(endDate) < new Date(startDate)) {
        this.bulkAddForm.get('endDate')?.setValue('');
      }
    }
  }

  async ngOnInit() {
    try {
      this.spinnerService.show();
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert') && this.loggedInUserInfo){
        this.loggedInExpert = await this.expertService.getOne(this.loggedInUserInfo['extraId']);
      }
      await this.initialize();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong on this page");
    }
  }

  async initialize() {
    this.user = await this.userService.getMyInformation();
    this.eventsLoaded = false;
    if ((this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('expert'))) {
      this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
    }
    if(this.groups.includes('expert') && this.loggedInExpert.isTeamLeader){
      this.filterExpertsForTeamLeader();
    }
    await this.setTimeAvailablities(this.expertId);
  }

  filterExpertsForTeamLeader(){
    if (this.loggedInExpert.expertTeamLeaderProvinces.length > 0) {
      this.experts = this.experts.filter((expert: Expert) => {
        if(expert.expertProvinces.length > 0){
          return expert.expertProvinces.some((eProvince: ExpertProvinces) => {
            if(eProvince.province != null){
              return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
                return eProvince.province.provinceCode.toLowerCase() === expertTeamLeaderProvince.province.provinceCode.toLowerCase()
              });
            }
          });
        }
      });
    }
  }
  async setTimeAvailablities(expertId: string|number){
    try {
      this.expert = await this.expertService.getTimeAvailavialityWithinDateRange(this.dateRangeObject, this.expertId);
      if (this.groups.includes('expert') && !this.loggedInExpert?.isTeamLeader) {
        if(this.expert?.id != this.loggedInExpert['extraId']){
          this.router.navigateByUrl("/404", { skipLocationChange: true });
        }
      }
      this.expertId = this.expert ? this.expert.id : null;
      let Events = this.expert?.timeAvailabilities.map(event => {
        let startTime = event.startTime.slice(0, -3);
        let endTime = event.endTime.slice(0, -3);
        return {
          title: (startTime + ' - ' + endTime),
          start: event.date,
          color: event?.available ? '#c2b55f' : '#e73e3e',
        };
      });
      this.Events = Events;
      this.eventsLoaded = true;
      if (this.Events && this.Events.length > 0) {
        this.calendarOptions.events = Events;
      } else {
        this.calendarOptions.events = [];
      }
    } catch (error) {
      
    }
  }

  handleDateClick(arg) {

    let data = this.expert?.timeAvailabilities.filter((data: any) => {
      return data?.date == arg.dateStr
    });

    let info = {
      date: arg.dateStr,
      expert: this.expert,
      userType: "expert",
      // createType: data === undefined ? "create" : "update",
      updateAbleData: data,
    }
    let ref = this.dialog.open(SetAvailabilityTimeComponent, { panelClass: 'expert-time-dialog', width: '50vw', data: { info } } );
    ref.afterClosed().subscribe(async (result) => {
      if (result?.success){
        await this.showSnackBar('Time Scheduled Successfully');
        await this.initialize();
      } else if(result?.status === false) {
        this.showSnackBar('Something went wrong');
      }
    });
  }

  handleAddBulkForm() {
    this.bulkAddForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: [''],
      endTime: [''],
      expert: [this.expertId],
      available: [true]
    })
  }

  async onSubmit(event: any){
    if(this.bulkAddForm.valid){
      this.btnDisable = true;
      let availabilityCheck = {
        "alldays": this.allDaysCheck,
        "weekend": this.weekendCheck,
        "monToFri": this.monToFriCheck,
      };

      if(this.bulkAddHanldle){
        this.bulkAddForm.value.days = this.days;
      } else {
        this.bulkAddForm.value.availabilityCheck = availabilityCheck;
      }
      let promise = this.expertService.setTimeAvailabilityInBulk(this.bulkAddForm.value);
      promise.then(async (res) => {
        if(res){
          this.bulkAddHanldle = false;
          await this.showSnackBar("Time Scheduled Successfully");
          await this.initialize();
          this.handleAddBulkForm();
          this.resetDaysCheckAndTime();
          this.resetCheckBoxes();
          this.btnDisable = false;
        }
      }).catch(async (err) => {
        await this.showSnackBar("Something went wrong");
        this.btnDisable = false;
      });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async handleExpertChange(expertId: any){
    try {
      this.spinnerService.show();
      this.expert = this.experts.find((e) => e.id === expertId);
      this.router.navigate(['expert', this.expert?.id, 'availability']);
      this.expertId = this.expert?.id;
      await this.setTimeAvailablities(this.expertId);
      this.handleAddBulkForm();
      this.handleRemoveAvailabilityForm();
      this.resetCheckBoxes();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something went wrong for getting selected valet details");
    }
  }

  checkOnlyOne(checkbox: string) {
    switch (checkbox) {
      case 'allDays':
        this.monToFriCheck = false;
        this.weekendCheck = false;
        break;
      case 'monToFri':
        this.allDaysCheck = false;
        this.weekendCheck = false;
        break;
      case 'weekend':
        this.allDaysCheck = false;
        this.monToFriCheck = false;
        break;
      default:
        break;
    }
  }

  resetCheckBoxes(){
    this.allDaysCheck = true;
    this.monToFriCheck = false;
    this.weekendCheck = false;
  }

  resetDaysCheckAndTime(){
    this.days = [
      { available: false, selected: false, startTime: '', endTime: '' },
      { available: false, selected: false, startTime: '', endTime: '' },
      { available: false, selected: false, startTime: '', endTime: '' },
      { available: false, selected: false, startTime: '', endTime: '' },
      { available: false, selected: false, startTime: '', endTime: '' },
      { available: false, selected: false, startTime: '', endTime: '' },
      { available: false, selected: false, startTime: '', endTime: '' }
    ];
  }

  async toggleOnlyOne(id){
    if(id === 'bulkAddHanldeCheckbox'){
      this.bulkAddHanldle = !this.bulkAddHanldle;
      this.bulkEditHanldle= false;
      this.removeAvaHandle = false;
    } else if(id === 'bulkEditHanldeCheckbox'){
      this.bulkEditHanldle = !this.bulkEditHanldle;
      this.bulkAddHanldle = false;
      this.removeAvaHandle = false;
    } else if(id === 'removeAvaCheckbox'){
      this.removeAvaHandle = !this.removeAvaHandle;
      this.bulkAddHanldle = false;
      this.bulkEditHanldle = false;
    }
  }

  handleSearchBulkForm() {
    this.bulkEditForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      expertId: [this.expertId]
    })
  }
  handleEditAvailabilityTimeBulkForm() {
    this.bulkEditAvailabilityTimeForm = this.fb.group({
      startTime: [''],
      endTime: [''],
      expertId: [this.expertId]
    })
  }

  handleRemoveAvailabilityForm(){
    this.removeAvailabilityForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      expert: [this.expertId]
    })
  }

  async onStartDateChangeForEdit() {
    const startDate = this.bulkEditForm.get('startDate')?.value;
    if (startDate) {
      this.minEndDate = startDate; // Set the `min` attribute of the `endDate` input
      // Optional: Reset `endDate` if it’s earlier than `startDate`
      const endDate = this.bulkEditForm.get('endDate')?.value;
      if (endDate && new Date(endDate) < new Date(startDate)) {
        this.bulkEditForm.get('endDate')?.setValue('');
      }
    }
  }

  async onSearch(event: any){
    if(this.bulkEditForm.valid){
      console.log(this.bulkEditForm);

      this.dateRangeObject = {
        fromDate: this.bulkEditForm.value.startDate,
        toDate: this.bulkEditForm.value.endDate
      }
      let promise = await this.expertService.getTimeAvailavialityWithinDateRange(this.dateRangeObject, this.expertId);
      console.log(promise);

      this.allDateTimeforEdit = Object.keys(promise.timeAvailabilities.reduce((acc, current) => {
        const date = current.date;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(current);
        return acc;
      }, {})).sort().reduce((acc, date) => {
        acc[date] = promise.timeAvailabilities.filter((item) => item.date === date);
        return acc;
      }, {});

      console.log(this.allDateTimeforEdit);

    }
  }

  get allDateKeys(): string[] {
    return Object.keys(this.allDateTimeforEdit);
  }

  async removeAvailabilityTime(date, index) {
    const dataArray = this.allDateTimeforEdit[date];
    if (dataArray.length > 1) {
      dataArray.splice(index, 1);
    }
  }

  async onUpdate(event: any){
    if(this.bulkEditAvailabilityTimeForm.valid){
      const collectedData = this.allDateKeys.map(date => ({
        expertId: this.expertId,
        date,
        times: this.allDateTimeforEdit[date],
      }));

      $('#updateBlukTime').prop('disabled', true);

      let promise = this.expertService.UpdateTimeAvailabilityInBulk(collectedData);
      promise.then(async (res) => {
        if(res){
          await this.initialize();
          this.handleSearchBulkForm();
          this.resetDaysCheckAndTime();
          this.resetCheckBoxes();
          this.allDateTimeforEdit = {};
          await this.showSnackBar("Time Scheduled Successfully");
        }
      }).catch(async (err) => {
        await this.showSnackBar("Something went wrong");
      });
    }
  }

  async onDatesSet(event) {
    try {
      const { start, end } = event;
      const formattedStart = this.formatDate(start);
      const formattedEnd = this.formatDate(end);
      this.dateRangeObject = {
        fromDate: formattedStart,
        toDate: formattedEnd
      }
      // this.expertId = this.route.snapshot.paramMap.get('id');
      await this.setTimeAvailablities(this.expertId);
    } catch (error) {
      console.log("ERROR", error?.message);
    }
  }

  formatDate(date: Date) {
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }

  async removeAvalability(){
    try {
      if(this.removeAvailabilityForm.valid){
        if (!confirm("Are you sure? All availability will be deleted between selected date range."))
          return;
        this.btnDisable = true;
        let promise = await this.expertService.removeAvailability(this.removeAvailabilityForm.value);
        await this.initialize();
        this.handleSearchBulkForm();
        this.resetDaysCheckAndTime();
        this.handleRemoveAvailabilityForm();
        this.resetCheckBoxes();
        this.allDateTimeforEdit = {};
        await this.showSnackBar("Availability data deleted successfully");
        this.btnDisable = false;
      }
    } catch (error) {
      await this.showSnackBar("Something went wrong");
      this.btnDisable = false;
    }
  }

}
