import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Delivery } from 'src/app/models/delivery.schema';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

  recieptForm: any;
  delivery: Delivery;
  otherOption: boolean = false;
  receiverOptions: any;
  pickUpFTime: any;
  pickUpTTime: any;
  pickUpFTimeError: boolean = false;
  pickUpTTimeError: boolean = false;
  isEditable: boolean = false;
  openFrom: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { delivery: Delivery, isEditable: boolean, openFrom: string },
    private fb: FormBuilder,
    private deliveryService: DeliveryService,
    private dialogRef: MatDialogRef<ReceiptComponent>,
  ) { 
    this.isEditable = this.data.isEditable;
    this.delivery = this.data?.delivery;
    this.openFrom = this.data?.openFrom;
  }

  ngOnInit() {
    this.handleRecieptForm();
  }

  handleRecieptForm(){
    this.recieptForm = this.fb.group({
      receiverSurname: [''],
      receiverName: [''],
      image: [null, [this.validateImageFile]],
    })
    this.receiverOptions = this.delivery?.receiverType;
    if(this.delivery?.receiverType != null){
      this.otherOption = this.delivery?.receiverType.toLowerCase() != 'recipient';
      this.recieptForm.patchValue({
        receiverSurname: this.delivery?.receiverType.toLowerCase() != 'recipient' ?  this.delivery?.receiverSurname : null,
        receiverName: this.delivery?.receiverType.toLowerCase() != 'recipient' ?  this.delivery?.receiverName : null
      });
    }
  }

  onFileSelect(event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.recieptForm.patchValue({
        image: file
      });
      this.recieptForm.get('image').updateValueAndValidity();
    }
  }

  validateImageFile(control) {
    const file = control.value;
    if (file) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        return { invalidImage: true };
      }
    }
    return null;
  }

  get imageControl() {
    return this.recieptForm.get('image');
  }

  /* Temporarily Commented out */
  async onSubmit(){
    if (this.recieptForm.valid) {
      this.recieptForm.value.receiverType = this.receiverOptions ? this.receiverOptions : null;
      if(this.delivery.ddtNumber && this.delivery.serviceType.toLowerCase() === 'sales' && this.openFrom.toLowerCase() === 'activities'){
        let deliveryData = await this.deliveryService.findDeliveriesWithDDTNumber(this.delivery);
        for(let delivery of deliveryData || []){
          await this.deliveryService.saveReceiptInfo(delivery?.id, this.recieptForm.value);
        }
        this.dialogRef.close({ success: true });
      } else {
        let promise = this.deliveryService.saveReceiptInfo(this.delivery?.id, this.recieptForm.value);
        promise.then(async (res: any) => {
          if(res){
            this.dialogRef.close({ success: true });
          }
        }).catch(async (err) => {
            console.log("err", err)
            this.dialogRef.close({ success: false });
        });
      }
    } 
  }
  /* Temporarily Commented out */

  /*
  async onSubmit(){
    if (this.recieptForm.valid) {
      this.recieptForm.value.receiverType = this.receiverOptions ? this.receiverOptions : null;
      let promise = this.deliveryService.saveReceiptInfo(this.delivery?.id, this.recieptForm.value);
      promise.then(async (res: any) => {
        if(res){
          this.dialogRef.close({ success: true });
        }
      }).catch(async (err) => {
          console.log("err", err)
          this.dialogRef.close({ success: false });
      });
    } 
  }
  */

  onSelectChange(option: any){
    if(option.toLowerCase() === 'other' || option.toLowerCase() === 'concierge'){
      this.otherOption = true;
    } else {
      this.otherOption = false;
      this.recieptForm.patchValue({
        receiverSurname: '',
        receiverName: ''
      });
    }
  }

}
