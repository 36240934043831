import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Delivery } from 'src/app/models/delivery.schema';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-delivery-not-delivered-dialog',
  templateUrl: './delivery-not-delivered-dialog.component.html',
  styleUrls: ['./delivery-not-delivered-dialog.component.scss']
})
export class DeliveryNotDeliveredDialogComponent implements OnInit {
  @ViewChild('notes') notes: ElementRef;
  delivery: Delivery;
  openFrom: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { delivery: Delivery, openFrom: string },
    private deliveryService: DeliveryService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<DeliveryNotDeliveredDialogComponent>,
  ) { 
    this.delivery = this.data.delivery;
    this.openFrom = this.data.openFrom;
  }

  ngOnInit() {
  }

  /* Temporarily Commented out */
  public async notDelivered(notes: string) {
    if (!confirm("Sei sicuro di voler impostare questa consegna come non consegnata?"))
      return;

    if(this.delivery.serviceType.toLowerCase() === 'sales' && this.delivery.ddtNumber && this.openFrom.toLowerCase() === 'activities'){
      let deliveryData = await this.deliveryService.findDeliveriesWithDDTNumber(this.delivery);
      for(let delivery of deliveryData || []){
        await this.deliveryService.setDeliveryNotDelivered(delivery.id, { notes });
      }
      this.dialogRef.close({ done: true });
    } else {
      return this.deliveryService.setDeliveryNotDelivered(this.delivery.id, { notes }).then(data => {
        this.snack(`Consegna aggiornata con successo`);
        this.dialogRef.close({ done: true });
      }).catch(err => {
        this.snack(`Errore nell'aggiornamento della consegna`);
        console.log(err);
        this.dialogRef.close({ done: false });
      });
    }

  }
  /*Temporarily Commented out */

  /*
  public async notDelivered(notes: string) {
    if (!confirm("Sei sicuro di voler impostare questa consegna come non consegnata?"))
      return;

    return this.deliveryService.setDeliveryNotDelivered(this.delivery.id, { notes }).then(data => {
      this.snack(`Consegna aggiornata con successo`);
      this.dialogRef.close({ done: true });
    }).catch(err => {
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
      this.dialogRef.close({ done: false });
    });

  }
  */

  snack(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  onSubmit() {
    if (!this.notes.nativeElement.value.trim()) {
      this.snack(`Note obbligatorie`);
      return;
    }
    return this.notDelivered(this.notes.nativeElement.value.trim());
  }

}
