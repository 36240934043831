import { Component, OnInit } from '@angular/core';
import { MapHelperService } from 'src/app/helpers/maps-service-helpher.service';
import { DeliveryStatus } from 'src/app/models/delivery.schema';
import { Expert } from 'src/app/models/expert.schema';

@Component({
  selector: 'app-show-map-direction',
  templateUrl: './show-map-direction.component.html',
  styleUrls: ['./show-map-direction.component.scss']
})
export class ShowMapDirectionComponent implements OnInit {

  adminValetActivities: any[] = [];
  expert: Expert;
  addresses: string[] = [];
  constructor(
    private mapHelperService: MapHelperService
  ) { }

  async ngOnInit() {
    let adminValetActivities = JSON.parse(localStorage.getItem('activities'));
    if(!adminValetActivities.length) {
      alert("No activities found");
      return;
    }

    if(adminValetActivities){
      this.adminValetActivities = adminValetActivities;
    }

    await this.renderMap();
  }

  async renderMap() {
    this.expert = this.adminValetActivities[0].delivery.expert;
    let addressesAndActivity: { activity: any; address: string }[] = this.makeAddress();
    const originAddress = addressesAndActivity[0].address; // First address
    const destination = addressesAndActivity[addressesAndActivity.length - 1].address;   // Last address
    const waypoints = addressesAndActivity.slice(1, -1).map((address) => ({ location: address.address, stopover: true, activity: address.activity })); // Middle addresses
  
    const processMap = async (mapId: string, optimize: boolean) => {
      const map = this.mapHelperService.initializeMap(mapId, { lat: 41.8719, lng: 12.5674 }, 6);
      const routeResponse = await this.mapHelperService.calculateAndDisplayRoute(
        map,
        originAddress,
        destination,
        waypoints.map((address) => ({ location: address.location, stopover: true })),
        optimize
      );

      const _activityData: any = [addressesAndActivity[0]];
      routeResponse.routes[0].waypoint_order.forEach((index) => {
        _activityData.push(waypoints[index]);
      });
      _activityData.push(addressesAndActivity[addressesAndActivity.length - 1]);

      // const sortedAddresses = optimize
      // ? [
      //     addressesAndActivity[0].address,
      //     ...routeResponse.routes[0].waypoint_order.map((i) => waypoints[i].location),
      //     addressesAndActivity[addressesAndActivity.length - 1].address,
      //   ]
      // : addressesAndActivity.map((address) => address.address);

      let newActivityArray = _activityData.map((sAddress) => {
        const address = addressesAndActivity.find((addActivity) => addActivity.activity.id == sAddress.activity.id);
        return address;
      });

      const data = this.mapHelperService.calculateTimeAndDistance(routeResponse.routes[0].legs);
      this.mapHelperService.addPinsWithActivities(map, newActivityArray);
      return data;
    };
  
    try {
      await processMap('optimizedMap', true);
    } catch (error) {}
  }

  makeAddress() {
    let addressList: { activity: any; address: string }[] = [];
    this.adminValetActivities.forEach((activity) => {
      if (activity.activityType.toLowerCase() === 'pickup') {
        addressList.push({ activity: activity, address: activity.delivery.pickUpAddress });
      }
      if (activity.activityType.toLowerCase() === 'delivery') {
        addressList.push({ activity: activity, address: activity.delivery.address });
      }
      if (activity.activityType.toLowerCase() === 'hourlyservice') {
        if (activity.delivery.status === DeliveryStatus.assigned) {
          addressList.push({ activity: activity, address: activity.delivery.pickUpAddress });
        }
        if (activity.delivery.status === DeliveryStatus.delivering) {
          addressList.push({ activity: activity, address: activity.delivery.address });
        }
      }
    });
    return addressList;
  }  

}
