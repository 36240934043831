import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'src/app/models/customer.schema';
import { User } from 'src/app/models/user.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { CustomersService } from 'src/app/services/customers.service';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {

  @Input('profile') public profile: boolean = false;
  customerForm: any;
  formValidationMsgs: any;
  groups: string[];
  tmpBtnDisabled: boolean = false;
  customer: Customer;
  today: string;
  
  constructor(
    private customerService: CustomersService,
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
    this.formValidationMsgs = Customer.validationMessages;
    const now = new Date();
    this.today = now.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    this.customerForm = new FormGroup(Customer.validation(this.profile));
    const customerID = this.profile ?
      (await this.customerService.getOne(this.authStatusService.getExtra()['id'])).id.toString() :
      this.route.snapshot.paramMap.get('id');
    if (customerID) {
      try {
        this.customer = await this.customerService.getOne(customerID);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }

    if (!this.customer) {
      this.customer = new Customer();
      this.customer.user = this.route.snapshot.paramMap.get('userId') ?
        await this.customerService.getOne(this.route.snapshot.paramMap.get('userId')) :
        new User();
    }

    this.patch();

    // if (this.profile)
    //   this.setProfileValidators();

    /* Temp Code
    const emailField = this.customerForm.get('user').get('email');
    emailField.clearValidators();
    emailField.updateValueAndValidity();
    Temp Code */
  }

  patch() {
    ['phone', 'address', 'notes', 'longitude', 'latitude', 'dob', 'intercom'].forEach(param => {
      this.customerForm.controls[param].setValue(this.customer[param]);
    });
    ['surname', 'name', 'email'].forEach(param => {
      this.customerForm.controls.user.controls[param].setValue(this.customer.user[param]);
    });
  }

  async onSubmit(){
    if(this.customerForm.valid){
      this.tmpBtnDisabled = true;
      let customer = (new FormGroupToObject()).transform(this.customerForm);  
      let promiseResult: any;
      // console.log("customer", customer);
      // return
      let createType: boolean = true;
      if (this.customer['id']) {
        customer['user']['id'] = this.customer.user.id;
        promiseResult = this.customerService.update(this.customer['id'], customer);
        createType = false;
      }
      else {
        if (this.customer.user.id){
          customer['user']['id'] = this.customer.user.id;
        }
        promiseResult = this.customerService.create(customer);
      }

      promiseResult.then((data: Customer) => {
        this.customer = data;
        const message: string = `Customer ${createType ? "creato" : "aggiornato"} con successo`;
        this._snackBar.open(message, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
          if (createType) {
            if (this.route.snapshot.paramMap.get('userId')){
              this.router.navigate(['/utenti']);
            } else{
              this.router.navigate(['/customer', 'edit', this.customer.id]);
            }
          }
        });
        // this.patch();
        this.tmpBtnDisabled = false;
      }).catch(error => {
        this.showSnackBar(error.error.message);
        this.tmpBtnDisabled = false;
        console.log(error);
      });

    }
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this.customerForm.controls.address.value}`, '_blank');
  }

  getAddress(place: any) {
    if (!this.customerForm) return;
    this.customerForm.controls.address.touched = true;
    this.customerForm.controls.address.setValue(place.formatted_address || place.name || '');
    this.customerForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this.customerForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}
