import { SpinnerService } from 'src/app/services/spinner.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Expert, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { ExpertService } from 'src/app/services/expert.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Delivery, DeliveryStatus, DeliveryStatusAux } from 'src/app/models/delivery.schema';
import { ExpertSalaryService } from 'src/app/services/expert-salary.service';
import { AdminValetActivityService } from 'src/app/services/admin-valet-activity.service';
import { AdminValetActivityHelpherService } from 'src/app/helpers/admin-valet-activity-helpher.service';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { ShowNotesComponent } from '../show-notes/show-notes.component';
import { MatDialog } from '@angular/material/dialog';
import { MapHelperService } from 'src/app/helpers/maps-service-helpher.service';


@Component({
  selector: 'app-admin-valet-activity',
  templateUrl: './admin-valet-activity.component.html',
  styleUrls: ['./admin-valet-activity.component.scss']
})
export class AdminValetActivityComponent implements OnInit {

  experts: Expert[] = [];
  deliveries: any[] = [];
  activities: any[] = [];
  showingDelivery: Delivery;
  showingActivity: any;
  filtered = {};
  activeAccordionDeliveryIndex: number | null = null;
  activeAccordionActivityIndex: number | null = null;
  selectedValet: Expert = null;
  selectedExpertId: any = null;
  adminValetActivities: any[] = [];
  deliveryStatusAux = DeliveryStatusAux;
  groups: any;
  loggedInExpert: Expert;
  isFilterAccordionOpen: boolean = false;
  isOptimizeMapAccordionOpen: boolean = false;
  isUnoptimizeMapAccordionOpen: boolean = false;
  activityFilterForm: any;
  isTeamLeader: boolean = false;
  selected: boolean;
  today: any;
  loggedInUserInfo: any;
  defaultActivityOrder = { 'AdminValetActivity.deliveryDate': 'ASC' };
  defaultDeliveryOrder = { 'Delivery.deliveryDate': 'DESC' };
  allActivities: any[] = [];
  addresses: string[] = [];
  unoptimizedData: { distance: string; duration: string } = {
    distance: "",
    duration: ""
  };
  optimizedData: { distance: string; duration: string } = {
    distance: "",
    duration: ""
  };
  showUnoptimizedSteps: boolean = false;
  showOptimizedSteps: boolean = false;
  optimzeMap: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private expertService: ExpertService,
    private expertSalaryService: ExpertSalaryService,
    private adminValetActivityService: AdminValetActivityService,
    private spinnerService: SpinnerService,
    private adminValetActivityHelpherService: AdminValetActivityHelpherService,
    private authStatusService: AuthStatusService,
    public dialog: MatDialog,
    private mapHelperService: MapHelperService,
  ) { 
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.selected = this.route.snapshot.data['active'];
  }

  async ngOnInit() {
    // this.selected = !this.route.snapshot.data.data['active'] ? 'inactive' : 'active';
    var today = new Date();
    var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    this.today = tmptoday;
    this.manageActivityFilterForm();
    await this.getAllActivities();
    await this.initialize();
    await this.filter();
    if(this.selected){
      this.mapHelperService.initializeMap("optimizedMap", { lat: 41.8719, lng: 12.5674 }, 6);
    }
  }

  manageActivityFilterForm(){
    this.activityFilterForm = new FormGroup({
      'AdminValetActivity.deliveryDate_from': new FormControl(this.today),
      'AdminValetActivity.deliveryDate_to': new FormControl(this.today),
      'AdminValetActivity.completed': new FormControl(),
      'Delivery.deliveryDate_from': new FormControl(this.today),
      'Delivery.deliveryDate_to': new FormControl(this.today),
      'Delivery.status': new FormControl(),
      'AdminValetActivity.customFilter': new FormControl(),
    });
  }

  async resetFilter() {
    this.activityFilterForm = new FormGroup({
      'AdminValetActivity.deliveryDate_from': new FormControl(), 
      'AdminValetActivity.deliveryDate_to': new FormControl(),
      'AdminValetActivity.completed': new FormControl(),
      'Delivery.deliveryDate_from': new FormControl(),
      'Delivery.deliveryDate_to': new FormControl(),
      'Delivery.status': new FormControl(),
      'AdminValetActivity.customFilter': new FormControl(),
    });
    await this.filter();
  }

  async filter() {
    await this.getAllActivities();
    await this.getDeliveriesAndActivities();
  }

  async getDeliveriesAndActivities(){
    if(this.selectedExpertId){
      if(this.selected === true){
        if(this.groups.includes('admin')){
          await this.loadDeliveries('active');
        }
        await this.loadActivities('active');
      } else if(this.selected === false){
        if(this.groups.includes('admin')){
          await this.loadDeliveries('inactive');
        }
        await this.loadActivities('inactive');
      }

      this.deliveries = this.deliveries.filter(delivery => {
        return !this.adminValetActivities.some(activity => activity.delivery.id === delivery.id);
      });
    }

    this.removeActivitiesIntoLocalStorage();
    this.setActivitiesIntoLocalStorage();
  }

  async loadDeliveries(mode: string){

    let filterForm = (new FormGroupToObject()).transform(this.activityFilterForm);
    this.filtered = {};

    delete this.filtered['AdminValetActivity.expertId'];
    delete this.filtered['AdminValetActivity.completed'];
    delete this.filtered['AdminValetActivity.deliveryDate'];

    this.filtered['Delivery.expertId'] = (this.selectedExpertId);
    this.filtered['Delivery.status'] = { operator: 'in', values: mode === 'active' ? DeliveryStatusAux.active : DeliveryStatusAux.inactive };

    if (filterForm['AdminValetActivity.deliveryDate_from'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['AdminValetActivity.deliveryDate_from']] };
    if (filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'lessThan', values: [filterForm['AdminValetActivity.deliveryDate_to']] };
    if (filterForm['AdminValetActivity.deliveryDate_from'] && filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'between', values: [filterForm['AdminValetActivity.deliveryDate_from'], filterForm['AdminValetActivity.deliveryDate_to']] };

    if (filterForm['AdminValetActivity.customFilter'])
      this.filtered['AdminValetActivity.customFilter'] = (`%${filterForm['AdminValetActivity.customFilter']}%`);

    let deliveries = await this.expertSalaryService.getAllDeliveries(this.selectedExpertId, 1, 1000, {}, this.filtered);
    this.deliveries = deliveries.deliveries;
  }

  async loadActivities(mode: string){

    let filterForm = (new FormGroupToObject()).transform(this.activityFilterForm);
    this.filtered = {};

    delete this.filtered['Delivery.expertId'];
    delete this.filtered['Delivery.status'];
    delete this.filtered['Delivery.deliveryDate'];

    this.filtered['AdminValetActivity.expertId'] = (this.selectedExpertId);
    this.filtered['AdminValetActivity.completed'] = mode === 'active' ? 0 : 1;

    if (filterForm['AdminValetActivity.deliveryDate_from'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['AdminValetActivity.deliveryDate_from']] };
    if (filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'lessThan', values: [filterForm['AdminValetActivity.deliveryDate_to']] };
    if (filterForm['AdminValetActivity.deliveryDate_from'] && filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [filterForm['AdminValetActivity.deliveryDate_from'], filterForm['AdminValetActivity.deliveryDate_to']] };

    if (filterForm['AdminValetActivity.customFilter'])
      this.filtered['AdminValetActivity.customFilter'] = (`%${filterForm['AdminValetActivity.customFilter']}%`);
    
    this.adminValetActivities = await this.adminValetActivityService.getAllWithDeliveryDateOrder(null, null, this.defaultActivityOrder, this.filtered);
  }

  async initialize(){
    try {
      this.selectedExpertId = null;
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert')){
        let teamLeader: Expert = await this.expertService.getOne(this.loggedInUserInfo['extraId']);
        this.isTeamLeader = teamLeader.isTeamLeader;
      }
      await this.getExperts();
    } catch (error) {
      console.log("ERROR====>", error);
    }
  }

  async getExperts(){
    this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
  }

  async getDeliveries(){

  }

  async handleValetChange(valetId: number){
    try {
      this.spinnerService.show();
      this.mapHelperService.initializeMap("optimizedMap", { lat: 41.8719, lng: 12.5674 }, 6);
      this.optimizedData.distance = '';
      this.optimizedData.duration = '';
      this.unoptimizedData.distance = '';
      this.unoptimizedData.duration = '';
      this.selectedValet = this.experts.find(v => v.id === Number(valetId));
      this.selectedExpertId = this.selectedValet?.id;
      await this.getAllActivities();
      await this.getDeliveriesAndActivities();
      // this.removeActivitiesIntoLocalStorage();
      // this.setActivitiesIntoLocalStorage();
      // var today = new Date();
      // var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
      // this.filtered = {};
      // this.filtered['Delivery.deliveryDate'] = { operator: 'between', values: [tmptoday, tmptoday] };
      // this.filtered['Delivery.status'] = { operator: 'in', values: DeliveryStatusAux.active };
      // let deliveries = await this.expertSalaryService.getAllDeliveries(valetId, 1, 1000, {}, this.filtered);
      // this.deliveries = deliveries.deliveries;
      // await this.getAdminValetActivity();
      this.spinnerService.hide();
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  setActivitiesIntoLocalStorage(){
    localStorage.setItem('activities', JSON.stringify(this.adminValetActivities));
  } 

  removeActivitiesIntoLocalStorage(){
    localStorage.removeItem('activities');
  }

  formatTimeShow(fromTime, toTime){
    let trimmedFromTime = fromTime != null ? fromTime?.slice(0, -3) : '';
    let trimmedToTime = toTime != null ? toTime?.slice(0, -3) : '';
    let time = trimmedFromTime + '-' + trimmedToTime;
    return time;
  }

  showServiceDetails(incomingDelivery: any){
    return incomingDelivery?.partner?.partnerServices.find((item) => item?.service?.id == incomingDelivery.service);
  }

  async drop(event: CdkDragDrop<any[]>) {
    try {
      if (event.previousContainer === event.container) {
        // This is for reordering within the same list (activity-listing only)
        if (event.container.id === 'activitiesList') {
          this.spinnerService.show();
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
          await this.updateReorderedActivities(event.container.data);
          // await this.getAdminValetActivity();
          await this.getAllActivities();
          await this.getDeliveriesAndActivities();
          this.spinnerService.hide();
        }
      } else {
        // This is for transferring items from deliveries-listing to activity-listing
        if (event.previousContainer.id === 'deliveriesList' && event.container.id === 'activitiesList') {
          this.spinnerService.show();
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
  
          const activity = event.container.data[event.currentIndex]; // get the newly added activity
          // await this.createActivity(activity);
          await this.adminValetActivityHelpherService.createOrUpdateActivities(activity);
          // await this.getAdminValetActivity();
          await this.getAllActivities();
          await this.getDeliveriesAndActivities();
          this.spinnerService.hide();
        }
      }
    } catch (error) {
      this.spinnerService.hide();
      console.log("Error", error);
    }
  }

  showDeliveryAndSaleIds(valetActivity: any): string | undefined {
    if (!valetActivity?.delivery?.ddtNumber) {
      return valetActivity?.delivery?.id;
    }
  
    if (valetActivity.activityType.toLowerCase() === 'delivery') {
      const deliveryActivities = this.allActivities.filter(
        (activity) =>
          activity.activityType.toLowerCase() === 'pickup' &&
          activity.delivery?.ddtNumber === valetActivity.delivery.ddtNumber
      );
      const deliveryIds = deliveryActivities.map((activity) => activity.delivery.id)
      return `${valetActivity.delivery.ddtNumber} (${deliveryIds.join(' + ')})`;
    }
  
    if (valetActivity.activityType.toLowerCase() === 'pickup') {
      return `${valetActivity.ddtNumber} (${valetActivity.delivery.id})`;
    }
  }

  getProducts(valetActivity: any): any[] {
    if (!valetActivity || !valetActivity.delivery) return [];
    
    const activityType = valetActivity.activityType?.toLowerCase();
    const serviceType = valetActivity.delivery.serviceType?.toLowerCase();
  
    if (activityType === 'hourlyservice' || serviceType === 'fixedprice' || (serviceType === 'sales' && !valetActivity.ddtNumber)) {
      return valetActivity.delivery.deliveryProducts || [];
    }
  
    if (serviceType === 'sales' && valetActivity.ddtNumber) {
      return this.showProducts(valetActivity);
    }
  
    return [];
  }  

  showProducts(valetActivity: any) {
    if (valetActivity.activityType.toLowerCase() === 'delivery') {
      const deliveryActivities = this.allActivities.filter(
        (activity) =>
          activity.activityType.toLowerCase() === 'pickup' &&
          activity.delivery?.ddtNumber === valetActivity.delivery.ddtNumber
      );
      const deliveryProducts = deliveryActivities
                                .map((activity) => activity.delivery.deliveryProducts)
                                .reduce((acc, products) => acc.concat(products), []);
      return deliveryProducts;
    } else if(valetActivity.activityType.toLowerCase() === 'pickup') {
      return valetActivity.delivery.deliveryProducts || [];
    } else {
      return [];
    }
  }

  async getAllActivities(){
    if(this.selectedExpertId){
      let filterForm = (new FormGroupToObject()).transform(this.activityFilterForm);
      this.filtered = {};

      delete this.filtered['Delivery.expertId'];
      delete this.filtered['Delivery.status'];
      delete this.filtered['Delivery.deliveryDate'];

      this.filtered['AdminValetActivity.expertId'] = (this.selectedExpertId);

      if (filterForm['AdminValetActivity.deliveryDate_from'])
        this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['AdminValetActivity.deliveryDate_from']] };
      if (filterForm['AdminValetActivity.deliveryDate_to'])
        this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'lessThan', values: [filterForm['AdminValetActivity.deliveryDate_to']] };
      if (filterForm['AdminValetActivity.deliveryDate_from'] && filterForm['AdminValetActivity.deliveryDate_to'])
        this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [filterForm['AdminValetActivity.deliveryDate_from'], filterForm['AdminValetActivity.deliveryDate_to']] };
      this.allActivities = await this.adminValetActivityService.getAllWithDeliveryDateOrder(null, null, {}, this.filtered);
      console.log("ALL ACTIVITIES", this.allActivities);
    }
  }

  async getAdminValetActivity(){
    try {
      let filtered = {};
      const today = new Date();
      const tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
      filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [tmptoday, tmptoday] };
      filtered['AdminValetActivity.expertId'] = Number(this.selectedValet.id);
      filtered['AdminValetActivity.completed'] = 0;
      let activities = await this.adminValetActivityService.getAdminValetActivity(filtered);
      this.adminValetActivities = activities;

      // Update deliveries by removing ones that have been added to activities
      this.deliveries = this.deliveries.filter(delivery => {
        return !this.adminValetActivities.some(activity => activity.delivery.id === delivery.id);
      });
    } catch (error) {
      
    }
  }

  async updateReorderedActivities(activities: any[]) {
    const reorderedActivities = activities.map((activity, index) => ({
      id: activity.id,
      order: index + 1, // This represents the new order
    }));
  
    await this.adminValetActivityService.updateActivityOrder(reorderedActivities);
  }
  
  toggleAccordion(type: string, index?: number){
    if(type === 'filter'){
      this.isFilterAccordionOpen = !this.isFilterAccordionOpen;
    } else if(type === 'optimizemap'){
      this.isOptimizeMapAccordionOpen = !this.isOptimizeMapAccordionOpen;
    } else if(type === 'unoptimizemap'){
      this.isUnoptimizeMapAccordionOpen = !this.isUnoptimizeMapAccordionOpen;
    } else if(type === 'activity'){
      if (this.activeAccordionActivityIndex === index) {
        this.activeAccordionActivityIndex = null;
      } else {
        this.activeAccordionActivityIndex = index; 
      }
      this.showingActivity = this.adminValetActivities[index];
    } else if(type === 'delivery'){
      if (this.activeAccordionDeliveryIndex === index) {
        this.activeAccordionDeliveryIndex = null;
      } else {
        this.activeAccordionDeliveryIndex = index; 
      }
      this.showingDelivery = this.deliveries[index];
    }
  }

  async showSnackBar(message: string) {
    this.snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async openNotesDialauge(delivery: Delivery){
    try {
      let ref = this.dialog.open(ShowNotesComponent, { width: '80vw', data: { delivery } });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  async changeActivityOrderAccordingTime(){
    
    try {
      if (!confirm("Sei sicuro? Tutti gli ordini saranno modificati in base all'orario di consegna e ritiro."))
        return;
      this.spinnerService.show();
      let newActivityArray = [];
      this.allActivities.forEach((activity: any) => {
        if (activity.activityType.toLowerCase() === "pickup") {
          newActivityArray.push({
            ...activity,
            timing: activity.delivery?.pickUpTime.split('-')[0]
          });
        }
        if (activity.activityType.toLowerCase() === "delivery") {
          newActivityArray.push({
            ...activity,
            timing: activity.delivery?.fromTime
          });
        }
        if (activity.activityType.toLowerCase() === "hourlyservice") {
          newActivityArray.push({
            ...activity,
            timing: activity.delivery.startTime ? activity.delivery.startTime : activity.delivery.fromTime ? activity.delivery.fromTime : ''
          });
        }
      });
  
      newActivityArray.sort((a, b) => {
        const timeA = a.timing.split(':').map(Number); 
        const timeB = b.timing.split(':').map(Number); 
        return timeA[0] - timeB[0] || timeA[1] - timeB[1];
      });
  
      newActivityArray.forEach((activity, i) => {
        activity.order = i + 1;
      });
  
      const resultArray = newActivityArray.map(activity => ({
        id: activity.id,
        order: activity.order
      }));
      
      await this.adminValetActivityService.updateActivityOrder(resultArray);
      await this.filter();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something Went Wrong");
      this.spinnerService.hide();
    }
  }

  async renderMap() {
    // const originAddress = this.selectedValet.address;
    let addressesAndActivity: { activity: any; address: string }[] = this.makeAddress();
    const originAddress = addressesAndActivity[0].address; // First address
    const destination = addressesAndActivity[addressesAndActivity.length - 1].address;   // Last address
    const waypoints = addressesAndActivity.slice(1, -1).map((address) => ({ location: address.address, stopover: true, activity: address.activity })); // Middle addresses
  
    const processMap = async (mapId: string, optimize: boolean) => {
      const map = this.mapHelperService.initializeMap(mapId, { lat: 41.8719, lng: 12.5674 }, 6);
      const routeResponse = await this.mapHelperService.calculateAndDisplayRoute(
        map,
        originAddress,
        destination,
        waypoints.map((address) => ({ location: address.location, stopover: true })),
        optimize
      );

      const _activityData: any = [addressesAndActivity[0]];
      routeResponse.routes[0].waypoint_order.forEach((index) => {
        _activityData.push(waypoints[index]);
      });
      _activityData.push(addressesAndActivity[addressesAndActivity.length - 1]);
      let newActivityArray = _activityData.map((sAddress) => {
        const address = addressesAndActivity.find((addActivity) => addActivity.activity.id == sAddress.activity.id);
        return address;
      });

      const data = this.mapHelperService.calculateTimeAndDistance(routeResponse.routes[0].legs);
      this.mapHelperService.addPinsWithActivities(map, newActivityArray);
      return data;
    };
  
    try {
      // this.unoptimizedData = await processMap('unoptimizedMap', false);
      this.optimizedData   = await processMap('optimizedMap', true);
      this.optimzeMap = true;
    } catch (error) {
      this.optimzeMap = false;
      if(error === 'MAX_WAYPOINTS_EXCEEDED'){
        this.showSnackBar("MAXIMUM_WAYPOINTS_WILL_25_PLEASE_TRY_WITH_25_OR_LESS");
      } else {
        this.showSnackBar(`MAP_OPTIMIZATION_ERROR: ${error}`);
      }
    }
  }

  makeAddress() {
    let addressList: { activity: any; address: string }[] = [];
    this.adminValetActivities.forEach((activity) => {
      if (activity.activityType.toLowerCase() === 'pickup') {
        addressList.push({ activity: activity, address: activity.delivery.pickUpAddress });
      }
      if (activity.activityType.toLowerCase() === 'delivery') {
        addressList.push({ activity: activity, address: activity.delivery.address });
      }
      if (activity.activityType.toLowerCase() === 'hourlyservice') {
        if (activity.delivery.status === DeliveryStatus.assigned) {
          addressList.push({ activity: activity, address: activity.delivery.pickUpAddress });
        }
        if (activity.delivery.status === DeliveryStatus.delivering) {
          addressList.push({ activity: activity, address: activity.delivery.address });
        }
      }
    });
    return addressList;
  }  

  async seeMapInNewWindow(){
    window.open('/optimized-route', '_blank'); 
  }

  resetMap(){
    this.mapHelperService.initializeMap("optimizedMap", { lat: 41.8719, lng: 12.5674 }, 6);
    this.optimzeMap = false;
  }
}
