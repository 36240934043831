import { AdminValetActivityService } from 'src/app/services/admin-valet-activity.service';
import { Component, OnInit } from "@angular/core";
import { DeliveryService } from "src/app/services/delivery.service";
import { Delivery, DeliveryStatus, DeliveryStatusAux } from "src/app/models/delivery.schema";
import { PartnerService } from "src/app/services/partner.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReceiptComponent } from "../receipt/receipt.component";
import { MatDialog } from "@angular/material/dialog";
import { DeliveryNotDeliveredDialogComponent } from "../delivery-not-delivered-dialog/delivery-not-delivered-dialog.component";
import { AuthStatusService } from "src/app/services/auth-status.service";
import { ActivatedRoute } from "@angular/router";
import { Expert } from 'src/app/models/expert.schema';
import { ExpertService } from 'src/app/services/expert.service';
import { CommanModalComponent } from '../comman-modal/comman-modal.component';
import { StartEndTimeEditComponent } from '../start-end-time-edit/start-end-time-edit.component';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ShowNotesComponent } from '../show-notes/show-notes.component';
import { MapHelperService } from 'src/app/helpers/maps-service-helpher.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApproveService22TimingsComponent } from '../approve-service22-timings/approve-service22-timings.component';
import { ApproveTimingsComponent } from '../approve-timings/approve-timings.component';


@Component({
  selector: 'app-valet-activities',
  templateUrl: './valet-activities.component.html',
  styleUrls: ['./valet-activities.component.scss']
})

export class ValetActivitiesComponent implements OnInit {

  dragAndDropDeliveriesList: any[] = [];
  valetActivities: any[] = [];
  currentExpert: Expert;
  filtered = {};
  deliveryStatus = DeliveryStatus;
  deliveryStatusAux = DeliveryStatusAux;
  disableBtn: boolean = false;
  loadingDeliveryId: number | null = null;
  groups: any;
  activeAccordionIndex: number | null = null;
  showingDelivery: Delivery;
  selected: boolean;
  firstUnlockedPickUpTime: string | null = null;
  intervalId: any;
  excludedActivities: any[] = [];
  public defaultOrder = { 'AdminValetActivity.deliveryDate': 'ASC' };
  isFilterAccordionOpen: boolean = false;
  activityFilterForm: any;
  today: any;
  allActivities: any[] = [];
  addresses: { activity: {}; address: string }[] = [];
  isOptimizeMapAccordionOpen: boolean = false;
  optimzeMap: boolean = false;

  constructor(
    private deliveryService: DeliveryService,
    private partnerService: PartnerService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authStatusService: AuthStatusService,
    private route: ActivatedRoute,
    private adminValetActivityService: AdminValetActivityService,
    private expertService: ExpertService,
    private spinnerService: SpinnerService,
    private mapHelperService: MapHelperService,
    private snackBar: MatSnackBar
  ){
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.selected = this.route.snapshot.data['active'];
  }

  async ngOnInit() {
    try {
      this.startInterval();
      this.manageActivityFilterForm();
      const loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert')){
        this.currentExpert = await this.expertService.getOne(loggedInUserInfo['extraId']);
      }
      await this.filter();
      await this.getAllActivities();
      if(this.selected){
        this.mapHelperService.initializeMap("optimizedMap", { lat: 41.8719, lng: 12.5674 }, 6);
      }
    } catch (error) {
      console.log(error);
    }
  }

  manageActivityFilterForm(){
    var today = new Date();
    var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    this.today = tmptoday;
    this.activityFilterForm = new FormGroup({
      'AdminValetActivity.deliveryDate_from': new FormControl(tmptoday),
      'AdminValetActivity.deliveryDate_to': new FormControl(tmptoday),
      'AdminValetActivity.customFilter': new FormControl(),
    });
  }

  resetFilter() {
    this.activityFilterForm = new FormGroup({
      'AdminValetActivity.deliveryDate_from': new FormControl(), 
      'AdminValetActivity.deliveryDate_to': new FormControl(),
      'AdminValetActivity.customFilter': new FormControl(),
    });
    this.filter();
  }

  startInterval() {
    this.intervalId = setInterval(() => {
      this.reloadActivities();
    }, 10000); // 10000 milliseconds = 10 seconds
  }

  async reloadActivities(){
    await this.getAllActivities();
    await this.getValetActivities();
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  async filter() {
    let filterForm = (new FormGroupToObject()).transform(this.activityFilterForm);
    this.filtered = {};

    if (filterForm['AdminValetActivity.deliveryDate_from'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['AdminValetActivity.deliveryDate_from']] };
    if (filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'lessThan', values: [filterForm['AdminValetActivity.deliveryDate_to']] };
    if (filterForm['AdminValetActivity.deliveryDate_from'] && filterForm['AdminValetActivity.deliveryDate_to'])
      this.filtered['AdminValetActivity.deliveryDate'] = { operator: 'between', values: [filterForm['AdminValetActivity.deliveryDate_from'], filterForm['AdminValetActivity.deliveryDate_to']] };
    if (filterForm['AdminValetActivity.customFilter'])
      this.filtered['AdminValetActivity.customFilter'] = (`%${filterForm['AdminValetActivity.customFilter']}%`);
    this.filtered['AdminValetActivity.expertId'] = (this.currentExpert.id);

    await this.getValetActivities();
  }

  async getValetActivities(){
    try {

      if(this.selected === true){
        this.filtered['AdminValetActivity.completed'] = 0;
        this.valetActivities = await this.adminValetActivityService.getAllWithDeliveryDateOrder(null, null, this.defaultOrder, this.filtered);
      } else if(this.selected === false){
        this.filtered['AdminValetActivity.completed'] = 1;
        this.valetActivities = await this.adminValetActivityService.getAllWithDeliveryDateOrder(null, null, this.defaultOrder, this.filtered);
      }

      // Set isLocked for valet activities
      /*
      this.valetActivities = this.valetActivities.map((activity, index) => ({
        ...activity,
        isLocked: index !== 0 // Only the first activity is unlocked
      }));
      this.unlockActivitiesByPickUpTime();
      */
      // console.log("VALET ACTIVITIES", this.valetActivities);

    } catch (error) {
      console.log(error);
    }
  }

  async getAllActivities(){
    let filter = { ...this.filtered };
    delete filter['AdminValetActivity.customFilter'];
    delete filter['AdminValetActivity.completed'];
    this.allActivities = await this.adminValetActivityService.getAllWithDeliveryDateOrder(null, null, {}, filter);
    // console.log("ALL ACTIVITIES", this.allActivities);
  }

  showDeliveryAndSaleIds(valetActivity: any): string | undefined {
    if (!valetActivity?.delivery?.ddtNumber) {
      return valetActivity?.delivery?.id;
    }
  
    if (valetActivity.activityType.toLowerCase() === 'delivery') {
      const deliveryActivities = this.allActivities.filter(
        (activity) =>
          activity.activityType.toLowerCase() === 'pickup' &&
          activity.delivery?.ddtNumber === valetActivity.delivery.ddtNumber
      );
      const deliveryIds = deliveryActivities.map((activity) => activity.delivery.id)
      return `${valetActivity.delivery.ddtNumber} (${deliveryIds.join(' + ')})`;
    }
  
    if (valetActivity.activityType.toLowerCase() === 'pickup') {
      return `${valetActivity.ddtNumber} (${valetActivity.delivery.id})`;
    }
  }

  getProducts(valetActivity: any): any[] {
    if (!valetActivity || !valetActivity.delivery) return [];
    
    const activityType = valetActivity.activityType?.toLowerCase();
    const serviceType = valetActivity.delivery.serviceType?.toLowerCase();
  
    if (activityType === 'hourlyservice' || serviceType === 'fixedprice' || (serviceType === 'sales' && !valetActivity.ddtNumber)) {
      return valetActivity.delivery.deliveryProducts || [];
    }
  
    if (serviceType === 'sales' && valetActivity.ddtNumber) {
      return this.showProducts(valetActivity);
    }
  
    return [];
  }

  showProducts(valetActivity: any) {
    if (valetActivity.activityType.toLowerCase() === 'delivery') {
      const deliveryActivities = this.allActivities.filter(
        (activity) =>
          activity.activityType.toLowerCase() === 'pickup' &&
          activity.delivery?.ddtNumber === valetActivity.delivery.ddtNumber
      );
      const deliveryProducts = deliveryActivities
                                .map((activity) => activity.delivery.deliveryProducts)
                                .reduce((acc, products) => acc.concat(products), []);
      return deliveryProducts;
    } else if(valetActivity.activityType.toLowerCase() === 'pickup') {
      return valetActivity.delivery.deliveryProducts || [];
    } else {
      return [];
    }
  }

  
  unlockActivitiesByPickUpTime() {
    // Find the first unlocked activity
    const firstUnlockedActivity = this.valetActivities.find(activity => !activity.isLocked);
    console.log("firstUnlockedActivity", firstUnlockedActivity);
    
    if (firstUnlockedActivity) {
      // Store the pickUpTime of the first unlocked activity
      this.firstUnlockedPickUpTime = firstUnlockedActivity.delivery.pickUpTime;

      // Unlock all activities with the same pickUpTime
      this.valetActivities.forEach(activity => {
        if ((activity.delivery.pickUpTime === this.firstUnlockedPickUpTime) && (activity.activityType.toLowerCase() === 'pickup')) {
          activity.isLocked = false;  // Unlock the activity
        }
      });
    }
  }

  public async delivering(id: number, activityId: number) {
    try {
      let delivery = await this.deliveryService.getOne(id); 
      let partner = await this.partnerService.getOne(delivery.partner.id);
      let sendSmsConfirmation: boolean = false;
      if (!confirm("Sei sicuro di voler impostare questa consegna come in consegna?"))
        return;
      if((partner.sendSms && delivery.smsPhoneNo) || (delivery.createdUser == 1 && delivery.smsPhoneNo && delivery.deluxyDelivery)){
        if (confirm("Vuoi inviare sms?"))
          sendSmsConfirmation = true;
      }

      this.loadingDeliveryId = id;
      return this.deliveryService.setDeliveryDelivering(id, sendSmsConfirmation).then(async (data) => {
        let status = {
          "completed": 1
        }
        await this.adminValetActivityService.updateValetActivity(activityId, status);
        await this.getValetActivities();
        this.loadingDeliveryId = null; 
        this.snack(`Consegna aggiornata con successo`);
      }).catch(err => {
        this.loadingDeliveryId = null; 
        this.snack(`Errore nell'aggiornamento della consegna`);
        console.log(err);
      });
    } catch (error) {
      this.loadingDeliveryId = null; 
      this.snack(`Errore nell'aggiornamento della consegna`);
    }
  }

  public async delivered(id: number, activityId: number) {
    try {
      let delivery = await this.deliveryService.getOne(id); 
      let partner = await this.partnerService.getOne(delivery.partner.id);
      let sendSmsConfirmation: boolean = false;
      if (!confirm("Sei sicuro di voler impostare questa consegna come consegnata?"))
        return;
      if((partner.sendSms && delivery.smsPhoneNo)  || (delivery.createdUser == 1 && delivery.smsPhoneNo && delivery.deluxyDelivery)){
        if (confirm("Vuoi inviare sms?"))
          sendSmsConfirmation = true;
      }
      this.loadingDeliveryId = id;  
      let ref: any;
      let openFrom = 'activities';
      // ref = this.dialog.open(ReceiptComponent, { width: '80vw', data: { delivery, openFrom } });
      // /*
      if(delivery.serviceType.toLowerCase() === 'hourlyrate' && delivery.service != 22){
        ref  = this.dialog.open(CommanModalComponent, {
          width: '80vw',
          data: {
            component: StartEndTimeEditComponent,
            delivery: delivery,
            title: "Start and End Time" 
          }
        });
      } else if(delivery.serviceType.toLowerCase() === 'hourlyrate' && delivery.service == 22){
        ref  = this.dialog.open(CommanModalComponent, {
          width: '80vw',
          data: {
            component: ApproveTimingsComponent,
            delivery: delivery,
            title: "Start and End Time" 
          }
        });
      } else {
        let openFrom = 'activities';
        ref = this.dialog.open(ReceiptComponent, { width: '80vw', data: { delivery, openFrom } });
      }
      // */
      ref.afterClosed().subscribe(async (result) => {
        if(result?.success === true){
          if(delivery.ddtNumber && delivery.serviceType.toLowerCase() === 'sales'){
            await this.changeStatusDelivered(delivery, 'delivery', sendSmsConfirmation, activityId);
            await this.getValetActivities();
            this.loadingDeliveryId = null; 
            this.snack(`Consegna aggiornata con successo`);
          } else {
            return this.deliveryService.setDeliveryDelivered(id, sendSmsConfirmation).then(async (data) => {
              let status = {
                "completed": 1
              }
              await this.adminValetActivityService.updateValetActivity(activityId, status);
              await this.getValetActivities();
              this.loadingDeliveryId = null; 
              this.snack(`Consegna aggiornata con successo`);
            }).catch(err => {
              this.loadingDeliveryId = null; 
              this.snack(`Errore nell'aggiornamento della consegna`);
              console.log(err);
            });
          }
        } 
        if(result?.success === false){
          this.loadingDeliveryId = null; 
          this.snack(`Errore nell'aggiornamento della consegna`);
        }
        this.loadingDeliveryId = null; 
      });
    } catch (error) {
      this.snack(`Errore nell'aggiornamento della consegna`);
    }
  }

  public async notDelivered(delivery: Delivery, activityId: number) {
    try {
      this.loadingDeliveryId = delivery.id; 
      let openFrom = 'activities';
      let ref = this.dialog.open(DeliveryNotDeliveredDialogComponent, { width: '80vw', data: { delivery, openFrom }, disableClose: true } );
      ref.afterClosed().subscribe(async (result) => {
        if(result.done){
          let status = {
            "completed": 1
          }
          await this.adminValetActivityService.updateValetActivity(activityId, status);
          await this.getValetActivities();
        }
        this.loadingDeliveryId = null;
      });
    } catch (error) {
      this.loadingDeliveryId = null;
    }
  }

  async changeStatusDelivering(delivery: Delivery, activityType: string, sendSmsConfirmation: boolean = false){
    let deliveryData = await this.deliveryService.findDeliveriesWithDDTNumber(delivery.ddtNumber);
    for(let delivery of deliveryData || []){
      await this.deliveryService.setDeliveryDelivering(delivery.id, sendSmsConfirmation);
    }

    let filtered = {};
    filtered['AdminValetActivity.ddtNumber'] = delivery.ddtNumber;
    filtered['AdminValetActivity.completed'] = 0;
    filtered['AdminValetActivity.activityType'] = activityType;
    filtered['AdminValetActivity.expertId'] = delivery.expert.id;
    let activities = await this.adminValetActivityService.getAll(filtered);
    
    for(let activity of activities || []){
      await this.adminValetActivityService.updateValetActivity(activity.id, {
        completed: 1,
      });
    }
    await this.getValetActivities();
    this.loadingDeliveryId = null; 
    this.snack(`Consegna aggiornata con successo`);
  }

  async changeStatusDelivered(delivery: Delivery, activityType: string, sendSmsConfirmation: boolean = false, activityId: number){
    let deliveryData = await this.deliveryService.findDeliveriesWithDDTNumber(delivery);
    for(let delivery of deliveryData || []){
      await this.deliveryService.setDeliveryDelivered(delivery.id, sendSmsConfirmation)
    }
    let status = {
      "completed": 1
    }
    await this.adminValetActivityService.updateValetActivity(activityId, status);
    await this.getValetActivities();
    this.loadingDeliveryId = null; 
    this.snack(`Consegna aggiornata con successo`);
  }

  async changePickUpTime(id: number, activityId: number){
    try {
      let delivery = await this.deliveryService.getOne(id); 
      let ref = this.dialog.open(ReceiptComponent, { width: '80vw', data: { delivery, isEditable: true } });
      console.log("DELIVERY", delivery);
    } catch (error) {
      this.snack("Something went wrong");
    }
  }

  async changeStartAndEndTime(id: number){
    try {
      let delivery = await this.deliveryService.getOne(id); 
      let dialogRef  = this.dialog.open(CommanModalComponent, {
        width: '80vw',
        data: {
          component: StartEndTimeEditComponent,
          delivery: delivery,
          title: "Start and End Time" 
        }
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          await this.getValetActivities();
          this.snack('Time has been updated');
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  }


  snack(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  toggleAccordion(type: string, index?: number) {
    if(type === 'delivery'){
      if (this.activeAccordionIndex === index) {
        this.activeAccordionIndex = null;  
      } else {
        this.activeAccordionIndex = index;  
      }
      this.showingDelivery = this.valetActivities[index].delivery;
    } else if(type === 'optimizemap'){
      this.isOptimizeMapAccordionOpen = !this.isOptimizeMapAccordionOpen;
    } else if(type === 'filter'){
      this.isFilterAccordionOpen = !this.isFilterAccordionOpen;
    }
  }

  showServiceDetails(incomingDelivery: any){
    let data = this.valetActivities.find((data) => incomingDelivery.id == data.delivery.id);
    return data.delivery?.partner?.partnerServices.find((item) => item?.service?.id == incomingDelivery.service);
  }

  formatTimeShow(fromTime, toTime){
    let trimmedFromTime = fromTime != null ? fromTime?.slice(0, -3) : '';
    let trimmedToTime = toTime != null ? toTime?.slice(0, -3) : '';
    let time = trimmedFromTime + '-' + trimmedToTime;
    return time;
  }

  directions(event: Event, valetActivity: any) {
    event.preventDefault();
    let address: string = '';
    if(valetActivity.activityType.toLowerCase() === "pickup"){
      address = valetActivity?.delivery?.pickUpAddress
    }
    if(valetActivity.activityType.toLowerCase() === "delivery" || valetActivity.activityType.toLowerCase() === "hourlyservice"){
      address = valetActivity?.delivery?.address
    }
    window.open(`https://www.google.com/maps/dir//${address}`, '_blank');
  }

  async openNotesDialauge(delivery: Delivery){
    try {
      let ref = this.dialog.open(ShowNotesComponent, { width: '80vw', data: { delivery } });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  async renderMap() {
    // const originAddress = this.selectedValet.address;
    let addressesAndActivity: { activity: any; address: string }[] = this.makeAddress();
    const originAddress = addressesAndActivity[0].address; // First address
    const destination = addressesAndActivity[addressesAndActivity.length - 1].address;   // Last address
    const waypoints = addressesAndActivity.slice(1, -1).map((address) => ({ location: address.address, stopover: true, activity: address.activity })); // Middle addresses
    const processMap = async (mapId: string, optimize: boolean) => {
      const map = this.mapHelperService.initializeMap(mapId, { lat: 41.8719, lng: 12.5674 }, 6);
      const routeResponse = await this.mapHelperService.calculateAndDisplayRoute(
        map,
        originAddress,
        destination,
        waypoints.map((address) => ({ location: address.location, stopover: true })),
        optimize
      );

      const _activityData: any = [addressesAndActivity[0]];
      routeResponse.routes[0].waypoint_order.forEach((index) => {
        _activityData.push(waypoints[index]);
      });
      _activityData.push(addressesAndActivity[addressesAndActivity.length - 1]);

      // const sortedAddresses = optimize
      // ? [
      //     addressesAndActivity[0].address,
      //     ...routeResponse.routes[0].waypoint_order.map((i) => waypoints[i].location),
      //     addressesAndActivity[addressesAndActivity.length - 1].address,
      //   ]
      // : addressesAndActivity.map((address) => address.address);

      // console.log("sortedAddresses", sortedAddresses);

      let newActivityArray = _activityData.map((sAddress) => {
        const address = addressesAndActivity.find((addActivity) => addActivity.activity.id == sAddress.activity.id);
        return address;
      });
      const data = this.mapHelperService.calculateTimeAndDistance(routeResponse.routes[0].legs);
      this.mapHelperService.addPinsWithActivities(map, newActivityArray);
      return data;
    };
  
    try {
      await processMap('optimizedMap', true);
      this.optimzeMap = true;
    } catch (error) {
      this.optimzeMap = false;
      if(error === 'MAX_WAYPOINTS_EXCEEDED'){
        this.showSnackBar("MAXIMUM_WAYPOINTS_WILL_25_PLEASE_TRY_WITH_25_OR_LESS");
      } else {
        this.showSnackBar(`MAP_OPTIMIZATION_ERROR: ${error}`);
      }
    }
  }

  makeAddress() {
    let addressList: { activity: any; address: string }[] = [];
    this.valetActivities.forEach((activity) => {
      if (activity.activityType.toLowerCase() === 'pickup') {
        addressList.push({ activity: activity, address: activity.delivery.pickUpAddress });
      }
      if (activity.activityType.toLowerCase() === 'delivery') {
        addressList.push({ activity: activity, address: activity.delivery.address });
      }
      if (activity.activityType.toLowerCase() === 'hourlyservice') {
        if (activity.delivery.status === DeliveryStatus.assigned) {
          addressList.push({ activity: activity, address: activity.delivery.pickUpAddress });
        }
        if (activity.delivery.status === DeliveryStatus.delivering) {
          addressList.push({ activity: activity, address: activity.delivery.address });
        }
      }
    });


    console.log(addressList);
    return addressList;
  }  

  async drop(event: CdkDragDrop<any[]>) {
    this.spinnerService.show();
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    await this.updateReorderedActivities(event.container.data);
    await this.getAllActivities();
    await this.getValetActivities();
    this.spinnerService.hide();
  }

  async updateReorderedActivities(activities: any[]) {
    const reorderedActivities = activities.map((activity, index) => ({
      id: activity.id,
      order: index + 1,
    }));
  
    await this.adminValetActivityService.updateActivityOrder(reorderedActivities);
  }

  async changeTimings(delivery: Delivery){

    /*
    let dialogRef  = this.dialog.open(CommanModalComponent, {
      width: '80vw',
      data: {
        component: ApproveService22TimingsComponent,
        delivery: delivery,
        title: "Valet Start and End Time"
      }
    });
    */

    let dialogRef = this.dialog.open(CommanModalComponent, {
      width: '80vw',
      data: {
        component: ApproveTimingsComponent,
        delivery: delivery,
        title: "Start and End Time" 
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Modal closed with success');
      }
    });
  }

  resetMap(){
    this.mapHelperService.initializeMap("optimizedMap", { lat: 41.8719, lng: 12.5674 }, 6);
    this.optimzeMap = false;
  }

  async changeActivityOrderAccordingTime(){
    try {
      if (!confirm("Sei sicuro? Tutti gli ordini saranno modificati in base all'orario di consegna e ritiro."))
        return;
      this.spinnerService.show();
      let newActivityArray = [];
      this.allActivities.forEach((activity: any) => {
        if (activity.activityType.toLowerCase() === "pickup") {
          newActivityArray.push({
            ...activity,
            timing: activity.delivery?.pickUpTime.split('-')[0]
          });
        }
        if (activity.activityType.toLowerCase() === "delivery") {
          newActivityArray.push({
            ...activity,
            timing: activity.delivery?.fromTime
          });
        }
        if (activity.activityType.toLowerCase() === "hourlyservice") {
          newActivityArray.push({
            ...activity,
            timing: activity.delivery.startTime ? activity.delivery.startTime : activity.delivery.fromTime ? activity.delivery.fromTime : ''
          });
        }
      });
  
      newActivityArray.sort((a, b) => {
        const timeA = a.timing.split(':').map(Number); 
        const timeB = b.timing.split(':').map(Number); 
        return timeA[0] - timeB[0] || timeA[1] - timeB[1];
      });
  
      newActivityArray.forEach((activity, i) => {
        activity.order = i + 1;
      });
  
      const resultArray = newActivityArray.map(activity => ({
        id: activity.id,
        order: activity.order
      }));
      
      await this.adminValetActivityService.updateActivityOrder(resultArray);
      await this.filter();
      this.spinnerService.hide();
    } catch (error) {
      this.showSnackBar("Something Went Wrong");
      this.spinnerService.hide();
    }
  }

  async showSnackBar(message: string) {
    this.snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}