import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';
import { ExpertService } from 'src/app/services/expert.service';

@Injectable()
export class UserLoggedIn implements CanActivate {

    groups: any;
    teamLeader: any;
    isTeamLeader: boolean = false;
    constructor(
        private authService: AuthStatusService, 
        private router: Router, 
        public jwtHelper: JwtHelperService,
        private expertService: ExpertService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const res: boolean = await this.authService.isAuthenticated();
        if (res) {
            const loggedInUserInfo = await this.authService.getTokenInfo();
            /* Temporarily Commented out */
            if(loggedInUserInfo.roles.includes('expert')){
                const loggedInExpert = await this.expertService.getOne(loggedInUserInfo['extraId']);
                this.isTeamLeader = loggedInExpert?.isTeamLeader;
                if(!this.isTeamLeader){
                    this.router.navigate(['/activities/own']);    
                    return false;
                }
            }
           /* Temporarily Commented out */
            // this.router.navigate(['/']);
            // return false;
        }
        return !res;
    }
}
