import { CustomersService } from 'src/app/services/customers.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Customer } from 'src/app/models/customer.schema';
import { FormControl, FormGroup } from '@angular/forms';
import { UserActiveStatus } from 'src/app/models/user.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { read, utils, write, writeFile } from 'xlsx';
import { MapHelperService } from 'src/app/helpers/maps-service-helpher.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  groups: any;
  limit: number;
  page: number;
  count: number = 0;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'Customer.createdAt': 'DESC' };
  filtered = {};
  customerFilterForm: any;
  customers: Customer[] = [];

  constructor(
    private customersService: CustomersService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private authStatusService: AuthStatusService,
    private mapHelperService: MapHelperService,
  ) {
    this.manageCustomerFilterForm();
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    } catch (error) {
      this.groups = [];
    }
  }

  manageCustomerFilterForm(){
    this.customerFilterForm = new FormGroup({
      'Customer.id': new FormControl(''),
      'User.surname': new FormControl(''),
      'User.name': new FormControl(''),
      'User.email': new FormControl(''),
      'Customer.address': new FormControl(''),
      'Customer.phone': new FormControl(''),
      'Customer.intercom': new FormControl(''),
      'Customer.notes': new FormControl(''),
      'User.active': new FormControl(''),
      'Customer.Group.name': new FormControl(''),
      'Customer.dob_from': new FormControl(''),
      'Customer.dob_to': new FormControl(''),
      'Customer.dob': new FormControl(''),
    });
  }

  async ngOnInit() {
    await this.loadCustomers();
  }

  async loadCustomers(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let customers = await this.customersService.getAllCustomer(this.page, this.limit, this.order, this.filtered);
    this.customers = customers;
    // console.log("this.customers", this.customers);
    this.count = customers.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  translateActive(status) {
    return UserActiveStatus.find(as => as.value == status).ln.it;
  }

  async filter(){
  
    let filterForm = (new FormGroupToObject()).transform(this.customerFilterForm);
    this.filtered = {};

    if (filterForm['Customer.id'])
      this.filtered['Customer.id'] = filterForm['Customer.id'];    
    if (filterForm['User.active'])
      this.filtered['User.active'] = filterForm['User.active'];    
    if (filterForm['Customer.address'])
      this.filtered['Customer.address'] = { operator: 'like', values: [`%${filterForm['Customer.address']}%`] };
    if (filterForm['Customer.notes'])
      this.filtered['Customer.notes'] = { operator: 'like', values: [`%${filterForm['Customer.notes']}%`] };
    if (filterForm['Customer.phone'])
      this.filtered['Customer.phone'] = { operator: 'like', values: [`%${filterForm['Customer.phone']}%`] };
    if (filterForm['Customer.intercom'])
      this.filtered['Customer.intercom'] = { operator: 'like', values: [`%${filterForm['Customer.intercom']}%`] };
    if (filterForm['User.surname'])
      this.filtered['User.surname'] = { operator: 'like', values: [`%${filterForm['User.surname']}%`] };
    if (filterForm['User.name'])
      this.filtered['User.name'] = { operator: 'like', values: [`%${filterForm['User.name']}%`] };
    if (filterForm['User.email'])
      this.filtered['User.email'] = { operator: 'like', values: [`%${filterForm['User.email']}%`] };
    if (filterForm['Customer.Group.name'])
      this.filtered['Customer.Group.name'] = { operator: 'like', values: [`%${filterForm['Customer.Group.name']}%`] };
    if (filterForm['Customer.dob_from'])
      this.filtered['Customer.dob'] = { operator: 'moreThanOrEqual', values: [filterForm['Customer.dob_from']] };
    if (filterForm['Customer.dob_to'])
      this.filtered['Customer.dob'] = { operator: 'lessThan', values: [filterForm['Customer.dob_to']] };
    if (filterForm['Customer.dob_from'] && filterForm['Customer.dob_to'])
      this.filtered['Customer.dob'] = { operator: 'between', values: [filterForm['Customer.dob_from'], filterForm['Customer.dob_to']] };

    await this.loadCustomers();
  }

  async resetFilter(){
    this.manageCustomerFilterForm();
    this.filter();
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadCustomers();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  async deleteCustomer(customerId: number){
    try {
      if (!confirm("Sei sicuro di voler cancellare questo customer?"))
        return;
      let deleteResult: { id: number | string, success: boolean } = await this.customersService.delete(customerId);
      let message = "Cancellazione del customer fallita.";
      if (deleteResult.success) {
        this.loadCustomers();
        message = "Cancellazione del customer avvenuta con successo.";
      }
      this.snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    } catch (error) {
      console.log(error);
    }
  }

  async exportCustomers(){
    try {
      let res = await this.customersService.getCustomerXlsx(this.order, this.filtered);
      let anchor = document.createElement('a');
      anchor.download = 'customers.xlsx';
      anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
      anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
      anchor.click();
    } catch (error) {
      this.showToastMessage("Something went wrong");
      console.log("ERROR: ", error);
    }
  }

  async importCustomers(){
    let el = document.getElementById("importcsvcustomer");
    el.click();
  }

  async handleCustomerImport($event: any){
    try {
      const files = $event.target.files;
      if(files.length){
        const file = files[0];
        const reader = new FileReader();
        reader.onload = async (event: any) => {
          const wb = read(event.target.result, { type: 'array', cellDates: true });
          const sheets = wb.SheetNames;

          if (sheets.length) {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { raw: false });
            let customers: any = rows;
            for(let customer of customers) {
              let geoAddress = await this.mapHelperService.geoCodeAddress(customer.address);
              if(geoAddress){
                let newCostomer: any = {
                  address     :geoAddress[0].formatted_address || '',
                  latitude    :geoAddress[0].geometry?.location?.lat() || '',
                  longitude   :geoAddress[0].geometry?.location?.lng() || '',
                  dob         :customer.dob,
                  intercom    :customer.intercom,
                  notes       :customer.notes,
                  phone       :customer.phone,
                  user        :{
                    surname   :customer.surname,
                    name      :customer.name,
                    email     :customer.email
                  }
                };
                await this.customersService.importCustomerViaCSV(newCostomer);
                await this.loadCustomers();
              }
            }
            this.showToastMessage("Customers uploaded successfully");
          }
        }
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  downloadCsvFormat(){
    const headings = [[
      "surname",
      "name",
      "email",
      "dob",
      "intercom",
      "address",
      "notes",
      "phone",
    ]];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.book_append_sheet(wb, ws, 'Customers');
    writeFile(wb, 'customers.xlsx');
  }

  async showToastMessage(message: string){
    this.snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
