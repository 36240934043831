import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Delivery } from "src/app/models/delivery.schema";
import { DeliveryService } from "src/app/services/delivery.service";
import { CommanModalComponent } from "../comman-modal/comman-modal.component";

@Component({
  selector: "app-approve-service22-timings",
  templateUrl: "./approve-service22-timings.component.html",
  styleUrls: ["./approve-service22-timings.component.scss"],
})
export class ApproveService22TimingsComponent implements OnInit {
  delivery: Delivery;
  timingForm: any;
  previousValetTimeValues: { valetStartTime: string; valetEndTime: string } = {
    valetStartTime: "",
    valetEndTime: "",
  };
  btnDisabled: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<CommanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private deliveryService: DeliveryService,
    private _snackBar: MatSnackBar
  ) {
    this.delivery = data.delivery;
    this.previousValetTimeValues.valetStartTime =
      this.delivery?.valetStartTime?.slice(0, -3);
    this.previousValetTimeValues.valetEndTime =
      this.delivery?.valetEndTime?.slice(0, -3);
    this.manageTimingForm();
  }

  manageTimingForm() {
    this.timingForm = new FormGroup({
      valetStartTime: new FormControl(""),
      valetEndTime: new FormControl(""),
      startTime: new FormControl(""),
      endTime: new FormControl(""),
    });

    this.timingForm.patchValue({
      valetStartTime: this.delivery?.valetStartTime?.slice(0, -3),
      valetEndTime: this.delivery?.valetEndTime?.slice(0, -3),
      startTime: this.delivery?.startTime?.slice(0, -3),
      endTime: this.delivery?.endTime?.slice(0, -3),
    });
  }

  async ngOnInit() {}

  handleValetStartTimeChange(value: string) {
    this.timingForm.controls.valetStartTime.setValue(value);
    const startTime = new Date(
      "1970-01-01T" + this.timingForm.controls.valetStartTime.value + ":00"
    );
    const endTime = new Date(startTime.getTime() + 3600000);
    const endTimeString = this.formatTime(endTime);
    this.timingForm.controls.valetEndTime.setValue(endTimeString);
    this.calculateValetTimeDifference();
  }

  handleValetEndTimeChange(value: string) {
    this.timingForm.controls.valetEndTime.setValue(value);
    this.calculateValetTimeDifference();
  }

  calculateValetTimeDifference() {
    const startTime = new Date(
      "1970-01-01T" + this.timingForm.controls.valetStartTime.value + ":00"
    );
    const endTime = new Date(
      "1970-01-01T" + this.timingForm.controls.valetEndTime.value + ":00"
    );
    const timeDiff = endTime.getTime() - startTime.getTime();
    const hours = Math.floor(timeDiff / 3600000);
    if (hours <= 0) {
      alert("Minimum one hour is required");
      this.timingForm.controls.valetStartTime.setValue(
        this.previousValetTimeValues.valetStartTime
      );
      this.timingForm.controls.valetEndTime.setValue(
        this.previousValetTimeValues.valetEndTime
      );
      return false;
    }
    /*
      const minutes = Math.floor((timeDiff % 3600000) / 60000);
      const totalTime = hours + minutes / 60;
      let totalValetTimeDifference: any = totalTime.toFixed(2);
      this.previousValetTimeValues.valetStartTime = this.timingForm.controls.valetStartTime.value;
      this.previousValetTimeValues.valetEndTime = this.timingForm.controls.valetEndTime.value;
      this.timingForm.controls.valetStartTime.setValue(this.previousValetTimeValues.valetStartTime);
      this.timingForm.controls.valetEndTime.setValue(this.previousValetTimeValues.valetEndTime);
  
      let expertService = this.delivery.expert.expertServices.find((expertService) => {
        return expertService.id == this.delivery.expertServiceId;
      });
      let valetSalary = expertService != undefined ? (totalValetTimeDifference * expertService.salary)?.toFixed(2) : this.delivery.expertSalary;
      this.timingForm.controls.expertSalary.setValue(valetSalary);
      */
  }

  formatTime(date: Date): string {
    return date.toTimeString().slice(0, 5); // Format as HH:MM
  }

  async onSubmit() {
    try {
      this.btnDisabled = true;
      if (this.timingForm.valid) {
        let promise = await this.deliveryService.serviceApprove(
          this.delivery?.id,
          this.timingForm.value
        );
        if (promise) {
          this.btnDisabled = false;
          if (promise) {
            this.dialogRef.close(true);
            this.showSnackBar("Valet Start and End Time has been updated");
          }
        }
      }
    } catch (error) {
      this.btnDisabled = false;
      this.showSnackBar(error?.error?.message);
    }
  }

  showSnackBar(message: string) {
    this._snackBar.open(message, "Chiudi", {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top",
    });
  }
}
