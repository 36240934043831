import { AbstractControl, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { Product } from './product.schema';
import { EnumAux } from '../helpers/enum-aux.helper';
import { Partner } from './partner.schema';
import { Expert } from './expert.schema';
import { phoneValid } from '../helpers/validators/phone.validator';
import { Transform } from 'class-transformer';
import { User } from './user.schema';

export const TimePeriods = {
    '07.00-08.00': { from: '07:00:00', to: '08:00:00' },
    '08.00-09.00': { from: '08:00:00', to: '09:00:00' },
    '09.00-10.00': { from: '09:00:00', to: '10:00:00' },
    '10.00-11.00': { from: '10:00:00', to: '11:00:00' },
	'11.00-12.00': { from: '11:00:00', to: '12:00:00' },
    '12.00-13.00': { from: '12:00:00', to: '13:00:00' },
    '13.00-14.00': { from: '13:00:00', to: '14:00:00' },
    '14.00-15.00': { from: '14:00:00', to: '15:00:00' },
    '15.00-16.00': { from: '15:00:00', to: '16:00:00' },
    '16.00-17.00': { from: '16:00:00', to: '17:00:00' },
    '17.00-18.00': { from: '17:00:00', to: '18:00:00' },
    '18.00-19.00': { from: '18:00:00', to: '19:00:00' },
    '19.00-20.00': { from: '19:00:00', to: '20:00:00' },
    '20.00-21.00': { from: '20:00:00', to: '21:00:00' },
    '21.00-22.00': { from: '21:00:00', to: '22:00:00' },

}

export enum DeliveryStatus {
    created = "created",
    assigned = "assigned",
    delivered = "delivered",
    invalidated = "invalidated",
    canceled = "canceled",
    delivering = "delivering",
    notDelivered = "notDelivered",
    inPreparation = "inPreparation",
    accepted = "accepted",
    notAccepted = "notAccepted",
    requestCancellation="requestCancellation",

}

export class DeliveryStatusAux extends EnumAux {
    static active = ["created", "assigned", "delivering", "inPreparation", "accepted", "requestCancellation"];
    static inactive = ["delivered", "notDelivered", "invalidated", "canceled", "notAccepted"];
    static mixed = ["created", "assigned", "delivering", "inPreparation", "accepted", "requestCancellation", "delivered", "notDelivered", "notAccepted"];

    static aux = {
        created: { css: "red", ln: { it: "Da gestire" } },
        assigned: { css: "yellow", ln: { it: "In gestione" } },
        delivered: { css: "green", ln: { it: "Consegnata" } },
        invalidated: { css: "black", ln: { it: "Annullata in fase di gestione" } },
        canceled: { css: "grey", ln: { it: "Annullata" } },
        delivering: { css: "purple", ln: { it: "In consegna" } },
        notDelivered: { css: "blue", ln: { it: "Non consegnata" } },
        inPreparation: { css: "grey", ln: { it: "In preparazione" } },
        accepted: { css: "grey", ln: { it: "Accettata" } },
        notAccepted: { css: "grey", ln: { it: "Non Accettata" } },
        requestCancellation: {css: "aqua",ln: { it:"Richiedi Annullamento"}},
    }
}

export class Delivery {
    public static validation(): { [key: string]: AbstractControl } {

        var user = new User();
        user = JSON.parse(sessionStorage.getItem("currentUser"));

        let validator: { [key: string]: AbstractControl } = {
            surname: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            email: new FormControl(''),
            address: new FormControl('', [Validators.required, this.invalidAddressValidator]),
            intercom: new FormControl('', Validators.required),
            createdUser: new FormControl(user.id),
            longitude: new FormControl(''),
            latitude: new FormControl(''),
            deliveryDate: new FormControl('', Validators.required),
            fromTime: new FormControl(''),
            toTime: new FormControl(''),
            notes: new FormControl(''),
            internalNotes: new FormControl(''),
            ddtNumber: new FormControl(''),
            ddtFile: new FormControl(undefined),
            receiverPhone: new FormControl('', phoneValid()),
            senderSurname: new FormControl(''),
            senderName: new FormControl(''),
            deliveryReadAt: new FormControl(''),
            senderPhone: new FormControl('', phoneValid()),
            status: new FormControl(DeliveryStatus.created),
            requestExpert: new FormControl(false),
            payAtDelivery: new FormControl(false),
            deluxyDelivery: new FormControl(false),
            tryAndReturn: new FormControl(false),
            deliveryProducts: new FormArray([new FormGroup(DeliveryProduct.validation())]),
            partner: new FormGroup({ id: new FormControl('') }),
            expert: new FormGroup({ id: new FormControl('') }),
            pickUpTime: new FormControl('', Validators.required),
            deliveryReadBy: new FormControl(''),
            smsPhoneNo: new FormControl('', phoneValid()),
            hours: new FormControl(''),
            pickUpAddress: new FormControl(''),
            service: new FormControl('', Validators.required),
            serviceType: new FormControl(''),
            startTime: new FormControl(''),
            endTime: new FormControl(''),
            productValue: new FormControl(''),
            price: new FormControl(''),
            additionalPrice: new FormControl(0),
            distance: new FormControl(''),
            isFlexblePickUpTime: new FormControl(false),
            serviceName: new FormControl(''),
            billable: new FormControl(true),
            payable: new FormControl(true),
            expertServiceId: new FormControl(''),
            expertSalary: new FormControl(''),
            valetAdditionalPrice: new FormControl(0),
            valetStartTime: new FormControl(''),
            valetEndTime: new FormControl(''),
            receipt: new FormControl(''),
            receiverType: new FormControl(''),
            receiverName: new FormControl(''),
            receiverSurname: new FormControl(''),
            deliveryRuleId: new FormControl(null),
            withTotalDeliveryRule: new FormControl(null),
            withDailyDeliveryRule: new FormControl(null),
            province: new FormControl(''),
            existingCustomer:new FormControl(false),
            customer: new FormGroup({ id: new FormControl('') }),
        };
        return validator;
    }

    public static invalidAddressValidator(control: FormControl) {
        if (control.value === '' || control.hasError('invalidAddress')) {
          return { invalidAddress: true };
        }
        return null;
    }

    public static productFormGroup() {
        return new FormGroup(DeliveryProduct.validation());
    }

    public static readonly validationMessages = {
        'name': [{ type: 'required', message: 'Obbligatorio' }],
        'surname': [{ type: 'required', message: 'Obbligatorio' }],
        'address': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'invalidAddress', message: 'Indirizzo non valido. Si prega di selezionare un indirizzo valido dall\'elenco.' }
        ],
        'intercom': [{ type: 'required', message: 'Obbligatorio' }],
        'pickUpTime': [{ type: 'required', message: 'Obbligatorio' }],
        'receiverPhone': [{ type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'senderPhone': [{ type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'smsPhoneNo': [{ type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'deliveryDate': [
            { type: 'required', message: 'Obbligatoria' },
            { type: 'moreThan', message: 'Non può essere nel passato' }
        ],
        'deliveryProducts': {
            'product': {
                'id': [{ type: 'required', message: 'Obbligatorio' }]
            },
        },
        'partner': {
            'id': [{ type: 'required', message: 'Obbligatorio' }]
        },
        'expert': {
            'id': []
        },
        'service': [{ type: 'required', message: 'Obbligatorio' }],
        'customer': {
            'id': [{ type: 'required', message: 'Obbligatorio' }]
        },
    };

    constructor(
        public id?: number,
        public status?: DeliveryStatus,
        public identifier?: string,
        public surname?: string,
        public name?: string,
        public email?: string,
        public address?: string,
        public intercom?: string,
        public longitude?: string,
        public latitude?: string,
        public city?: string,
        deliveryDate?: Date,
        actualDate?: Date,
        public fromTime?: Date,
        public toTime?: Date,
        public notes?: string,
        public internalNotes?: string,
        public ddtNumber?: string,
        public ddtFile?: string,
        public receiverPhone?: string,
        public senderSurname?: string,
        public senderName?: string,
        public senderPhone?: string,
        public requestExpert?: boolean,
        public payAtDelivery?: boolean,
        public deluxyDelivery?: boolean,
        public tryAndReturn?: boolean,
        public deliveryProducts?: DeliveryProduct[],
        public partner?: Partner,
        public expert?: Expert,
        public createdAt?: Date,
        public updatedAt?: Date,
        public readDelivery?: boolean,
        public createdUser?: number,
        public pickUpTime?: string,
        public deliveryReadAt?: Date,
        public deliveryReadBy?: string,
        public deliveryReadByPartner?: number,
        public deliveryReadByExpert?: number,
        public deliveryReadAtByExpert?: Date,
        public deliveryReadAtByPartner?: Date,
        public deliveryStartedAt?: Date,
        public deliveryDeliveredAt?: Date,
        public smsPhoneNo?: string,
        public hours?: number,
        public pickUpAddress?: string,
        public service?: string|number,
        public serviceType?: string,
        public startTime?: string,
        public endTime?: string,
        public productValue?: string|number,
        public price?: string|number,
        public additionalPrice?: string,
        public distance?: string,
        public serviceName?: string,
        public isFlexblePickUpTime?: boolean,
        public billable?: boolean,
        public payable?: boolean,
        public expertServiceId?: string|number,
        public expertSalary?: string,
        public valetAdditionalPrice?: string,
        public valetStartTime?: string,
        public valetEndTime?: string,
        public receipt?: string,
        public receiverSurname?: string,
        public receiverName?: string,
        public receiverType?: string,
        public createdFrom?: string,
        public acceptSale?: number,
        public shop?:string,
        public withDailyDeliveryRule?:boolean,
        public withTotalDeliveryRule?:boolean,
        public deliveryRuleId?: number,
        public approvedTimingStatus?: number,
        public approvedTimings?: string,
        public orderId?: number,
        public province?: string,
        public existingCustomer?: boolean,
        public customer?: any,
    ) {
        this.deliveryDate = deliveryDate;
        this.actualDate = actualDate;

    }
    @Transform(toDate, { toClassOnly: true }) public deliveryDate?: Date;
    @Transform(toDate, { toClassOnly: true }) public actualDate?: Date;


}

export function toDate({ value, key, obj, type }) {
    return value ? new Date(value) : undefined;
}

export class DeliveryProduct {

    public static validationMessages: { [key: string]: { type: string; message: string }[] } = {
        product: [
            { type: 'required', message: 'Prodotto obbligatorio' },
        ],
        quantity: [
            { type: 'required', message: 'Quantità obbligatoria' },
        ],
    };
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            product: this.productFormGroup(),
        };
        return validator;
    }

    public static productFormGroup() {
        return new FormGroup({
            id: new FormControl('', Validators.required),
            quantity: new FormControl('1', Validators.required),
        });
    }

    constructor(
        public delivery?: Delivery,
        public product?: Product,
        public date?: Date,
        public quantity?: number,
        public price?: number,
        public updatedAt?: Date,
        public createdAt?: Date,
    ) { }
}
