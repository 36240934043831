import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';
import { Customer } from '../models/customer.schema';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/customer`;
  
  async getAllCustomer(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
    return this.getAll(page, limit, order, filter);
  }
  
  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any>{
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get(`${this.url}`, { params: params }).toPromise() );
  }

  async getOne(id: string|number): Promise<Customer> {
    return this.request(() => this.http.get<Customer>(`${this.url}/${id}`).toPromise());
  }

  async create(customerParams: Customer): Promise<Customer> {
    let body = customerParams;
    return this.request(() => this.http.post<Customer>(`${this.url}`, body).toPromise());
  }

  async update(id: number, customerParams: Customer): Promise<Customer> {
    let body = customerParams;
    return this.request(() => this.http.put<Customer>(`${this.url}/${id}`, body).toPromise());
  }

  async updateMe(customerParams: Customer): Promise<Customer> {
    let body = customerParams;
    return this.request(() => this.http.put<Customer>(`${this.url}`, body).toPromise());
  }

  async delete(id: number): Promise<{ id: number, success: boolean }> {
    return this.request(() => this.http.delete<{ id: number, success: boolean }>(`${this.url}/${id}`).toPromise());
  }

  async importCustomerViaCSV(customer: Customer): Promise<any>{
    let data = [];
    data.push(customer);
    return this.request(() =>
      this.http.post<Customer>(`${this.url}/import`, data).toPromise()
    );
  }

  async getCustomerXlsx(order?: {}, filter: any = {}): Promise<HttpResponse<Blob>> {
    return this.getAllXlsx(order, filter);
  }

  async getAllXlsx(order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<HttpResponse<Blob>> {
    let params = {};
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
      this.http
        .get(`${this.url}/xlsx/export`, {
          params: params,
          observe: 'response',
          responseType: 'blob',
        })
        .toPromise()
    );
  }
  

}
